import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/create-authority-manager.mapper";
import { EditAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/edit-authority-manager.mapper";
import { AuthorityManager } from "@entity/domain/models/authority-manager/authority-manager.model";
import { MandateTerminationReason } from "@entity/domain/models/mandate-termination-reason.model";
import { PositionType } from "@entity/domain/models/position-type.model";
import { CreateAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/create-authority-manager.usecase";
import { DeleteAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/delete-authority-manager.usecase";
import { EditAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/edit-authority-manager.usecase";
import { GetAllAuthorityManagersUsecase } from "@entity/domain/usecases/authority-manager/get-all-authority-managers.usecase";
import { GetAllMandateTerminationReasonsUseCase } from "@entity/domain/usecases/get-all-mandate-termination-reasons.usecase";
import { GetAllPositionTypesUseCase } from "@entity/domain/usecases/get-all-position-types.usecase";
import { AuthorityManagerFormValuesValidated } from "@entity/presentation/component/entity-form/authority-manager/form/authority-manager-form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class AuthorityManagerTabViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    authorityManagers: AuthorityManager[] = [];

    @observable
    authorityManagerToEdit: Undefinable<AuthorityManager> = undefined;

    @observable
    positionTypes: PositionType[] = [];

    @observable
    mandateTerminationReasons: MandateTerminationReason[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    showAddAuthorityManagerModal: boolean = false;

    @observable
    showEditAuthorityManagerModal: boolean = false;

    constructor(
        @inject(GetAllAuthorityManagersUsecase)
        private readonly getAllAuthorityManagersUsecase: GetAllAuthorityManagersUsecase,
        @inject(CreateAuthorityManagerUseCase)
        private readonly createAuthorityManagerUseCase: CreateAuthorityManagerUseCase,
        @inject(EditAuthorityManagerUseCase)
        private readonly editAuthorityManagerUseCase: EditAuthorityManagerUseCase,
        @inject(DeleteAuthorityManagerUseCase)
        private readonly deleteAuthorityManagerUseCase: DeleteAuthorityManagerUseCase,
        @inject(CreateAuthorityManagerMapper)
        private readonly createAuthorityManagerMapper: CreateAuthorityManagerMapper,
        @inject(EditAuthorityManagerMapper)
        private readonly editAuthorityManagerMapper: EditAuthorityManagerMapper,
        @inject(GetAllMandateTerminationReasonsUseCase)
        private readonly getAllMandateTerminationReasonsUseCase: GetAllMandateTerminationReasonsUseCase,
        @inject(GetAllPositionTypesUseCase)
        private readonly getAllPositionTypesUseCase: GetAllPositionTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAuthorityManagers(),
            this.getPositionTypes(),
            this.getMandateTerminationReasons(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAuthorityManagers(): Promise<void> {
        const authorityManagers =
            await this.getAllAuthorityManagersUsecase.execute();
        runInAction(() => {
            this.authorityManagers = authorityManagers;
        });
    }

    @action
    setShowAddAuthorityManagerModal(show: boolean): void {
        this.showAddAuthorityManagerModal = show;
    }

    @action
    closeEditAuthorityManagerModal(): void {
        this.showEditAuthorityManagerModal = false;
    }

    @action
    openEditAuthorityManagerModal(
        authorityManager: Undefinable<AuthorityManager>,
    ): void {
        this.authorityManagerToEdit = authorityManager;
        this.showEditAuthorityManagerModal = true;
    }

    async addAuthorityManager(
        entityId: Undefinable<number>,
        values: AuthorityManagerFormValuesValidated,
    ): Promise<Nullable<AuthorityManager>> {
        if (!entityId) throw Error("Missing Entity");

        LoadLayoutStore.start();

        const createAuthorityManagerResult =
            await this.createAuthorityManagerUseCase.execute(
                this.createAuthorityManagerMapper.mapFromFormValues(
                    entityId,
                    values,
                ),
            );

        if (createAuthorityManagerResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createAuthorityManagerResult;
    }

    async editAuthorityManager(
        values: AuthorityManagerFormValuesValidated,
    ): Promise<void> {
        if (
            !this.authorityManagerToEdit?.id ||
            !this.authorityManagerToEdit.entity
        )
            throw Error("Missing authorityManager");

        LoadLayoutStore.start();

        const editAuthorityManagerResult =
            await this.editAuthorityManagerUseCase.execute(
                this.editAuthorityManagerMapper.mapFromModal(
                    this.authorityManagerToEdit.id,
                    this.authorityManagerToEdit.entity,
                    values,
                ),
            );

        if (editAuthorityManagerResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadAuthorityManagers(): Promise<void> {
        await this.getAuthorityManagers();
    }

    async deleteAuthorityManager(authorityManagerId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteAuthorityManagerUseCase.execute(authorityManagerId);
        LoadLayoutStore.finish();
    }

    async getPositionTypes(): Promise<void> {
        const positionTypes = await this.getAllPositionTypesUseCase.execute();

        runInAction(() => {
            this.positionTypes = positionTypes;
        });
    }

    async getMandateTerminationReasons(): Promise<void> {
        const mandateTerminationReasons =
            await this.getAllMandateTerminationReasonsUseCase.execute();

        runInAction(() => {
            this.mandateTerminationReasons = mandateTerminationReasons;
        });
    }
}
