import { BeneficiariesSummary } from "@beneficiary/domain/models/beneficiaries-summary.model";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { GetRecentModifiedBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-recent-modified-beneficiaries.usecase";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GetEmployeesCountByTypeUseCase } from "@entity/domain/usecases/employee/get-employees-count-by-type.usecase";
import { ProceedingsSummary } from "@proceeding/domain/models/proceedings-sumary.model";
import { GetRecentModifiedDerivationsUseCase } from "@proceeding/domain/usecases/get-recent-modified-derivations.usecase";
import { GetRecentModifiedProceedingsUseCase } from "@proceeding/domain/usecases/get-recent-modified-proceedings.usecase";
import { ProjectEmployeesCount } from "@project/domain/models/project-employees.model";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { GetRecentsModifiedProjectsUseCase } from "@project/domain/usecases/get-recents-modified-projects.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { Pagination } from "../../domain/models/pagination";

export interface ProjectListTable {
    id: number;
    name: string;
    financialEntitiesList: string;
    status: string;
}

interface ProjectsListTable {
    projects: ProjectListTable[];
    count: number;
}

export interface BeneficiaryListTable {
    id: number;
    name: string;
    documentNumber: string;
}

interface BeneficiariesListTable {
    beneficiaries: BeneficiaryListTable[];
    count: number;
}

export interface ProceedingListTable {
    id: number;
    created: DateTime;
    state: string;
    beneficiaryData: BeneficiarySummary;
    projectName: string;
    derived: boolean;
}

export interface ProceedingsListTable {
    proceedingsSummary: ProceedingListTable[];
    count: number;
}

@injectable()
export class HomePageViewModel extends BaseViewModel {
    @observable
    initialLoading = true;

    @observable
    pagination: Pagination = Pagination.NoPagination();

    @observable
    employeesCount: ProjectEmployeesCount = new ProjectEmployeesCount(0, 0, 0);

    @observable
    private _projects: ProjectsSummary = new ProjectsSummary([], 0);

    @observable
    private _beneficiaries: BeneficiariesSummary = new BeneficiariesSummary(
        [],
        0,
    );

    @observable
    private _proceedings: ProceedingsSummary = new ProceedingsSummary([], 0);

    @observable
    private _derivations: ProceedingsSummary = new ProceedingsSummary([], 0);

    @computed
    get projectsTable(): ProjectsListTable {
        return {
            count: this._projects.count,
            projects: this._projects.projectsSummary.map((projectSummary) => ({
                id: projectSummary.id,
                name: projectSummary.name,
                financialEntitiesList:
                    projectSummary.financialEntityTypeLabels.join(", "),
                status: projectSummary.statusLabel,
            })),
        };
    }

    @computed
    get beneficiariesTable(): BeneficiariesListTable {
        return {
            count: this._beneficiaries.count,
            beneficiaries: this._beneficiaries.beneficiariesSummary.map(
                (beneficiarySummary) => ({
                    id: beneficiarySummary.id,
                    name: `${beneficiarySummary.name} ${beneficiarySummary.surname}`,
                    documentNumber: beneficiarySummary.documentNumber
                        ? beneficiarySummary.documentNumber
                        : "",
                }),
            ),
        };
    }

    @computed
    get proceedingsTable(): ProceedingsListTable {
        return {
            proceedingsSummary:
                this._proceedings.proceedingsSummary.map<ProceedingListTable>(
                    (proceedingSummary) => ({
                        id: proceedingSummary.id,
                        created: proceedingSummary.created,
                        state: proceedingSummary.statusLabel,
                        beneficiaryData: proceedingSummary.beneficiaryData,
                        projectName: proceedingSummary.nameProject,
                        derived: proceedingSummary.derivationDate !== undefined,
                    }),
                ),
            count: this._proceedings.count,
        };
    }

    @computed
    get projectsCount(): number {
        return this._projects.count;
    }

    @computed
    get beneficiariesCount(): number {
        return this._beneficiaries.count;
    }

    @computed
    get proceedingsCount(): number {
        return this._proceedings.count;
    }

    @computed
    get derivationsCount(): number {
        return this._derivations.count;
    }

    constructor(
        @inject(GetRecentModifiedBeneficiariesUseCase)
        private readonly getRecentModifiedBeneficiariesUseCase: GetRecentModifiedBeneficiariesUseCase,

        @inject(GetRecentsModifiedProjectsUseCase)
        private readonly getRecentsModifiedProjectsUseCase: GetRecentsModifiedProjectsUseCase,

        @inject(GetRecentModifiedProceedingsUseCase)
        private readonly getRecentModifiedProceedingsUseCase: GetRecentModifiedProceedingsUseCase,

        @inject(GetRecentModifiedDerivationsUseCase)
        private readonly getRecentModifiedDerivationsUseCase: GetRecentModifiedDerivationsUseCase,

        @inject(GetEmployeesCountByTypeUseCase)
        private readonly getEmployeesTypeCountUseCase: GetEmployeesCountByTypeUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.searchBeneficiariesBy(),
                this.searchProjectsBy(),
                this.searchProceedingsBy(),
                this.searchDerivationsBy(),
                this.getEmployeesCount(),
            ]),
        );

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @flow
    async *searchBeneficiariesBy(): AsyncGenerator<void> {
        const beneficiaries =
            await this.getRecentModifiedBeneficiariesUseCase.execute();

        runInAction(() => {
            this._beneficiaries = beneficiaries;
        });
    }

    @flow
    async *searchProjectsBy(): AsyncGenerator<void> {
        const projects = await this.getRecentsModifiedProjectsUseCase.execute();
        runInAction(() => {
            this._projects = projects;
        });
    }

    @flow
    async *searchProceedingsBy(): AsyncGenerator<void> {
        const proceedings =
            await this.getRecentModifiedProceedingsUseCase.execute();

        runInAction(() => {
            this._proceedings = proceedings;
        });
    }

    @flow
    async *searchDerivationsBy(): AsyncGenerator<void> {
        const derivations =
            await this.getRecentModifiedDerivationsUseCase.execute();

        runInAction(() => {
            this._derivations = derivations;
        });
    }

    @flow
    async *getEmployeesCount(): AsyncGenerator<void> {
        const employeesCount =
            await this.getEmployeesTypeCountUseCase.execute();

        if (employeesCount !== null) {
            runInAction(() => {
                this.employeesCount = employeesCount;
            });
        }
    }
}
