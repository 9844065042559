import i18n from "@i18n";
import { addMethod, string } from "yup";

// control dni letters
const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";

const validateDNI = (dni: string | undefined): boolean => {
    if (!dni) return false;

    const num = parseInt(dni.slice(0, dni.length - 1), 10);
    const letter = dni.charAt(dni.length - 1);
    const expectedLetter = dniLetters.charAt(num % 23);

    return expectedLetter === letter.toUpperCase();
};

const validateNIE = (nie: string | undefined): boolean => {
    if (!nie) return false;

    if (nie.startsWith("X")) {
        return validateDNI(`0${nie.slice(1)}`);
    } else if (nie.startsWith("Y")) {
        return validateDNI(`1${nie.slice(1)}`);
    } else if (nie.startsWith("Z")) {
        return validateDNI(`2${nie.slice(1)}`);
    }

    return false;
};

const validateCIF = (cif: string | undefined): boolean => {
    if (!cif) return false;

    const regex = /^[ABCDEFGHJNPQRSUVWLMXYZ][0-9]{7}[0-9A-J]$/;
    if (!regex.test(cif)) {
        return false;
    }

    const letters = "JABCDEFGHI";
    const calculatedLetter = cif[0];
    const digits = cif.slice(1, 8);
    const control = cif[8];

    let pairSum = 0;
    let oddSum = 0;
    for (let i = 0; i < digits.length; i++) {
        const num = parseInt(digits[i]);
        if (i % 2 === 0) {
            const double = num * 2;
            oddSum += Math.floor(double / 10) + (double % 10);
        } else {
            pairSum += num;
        }
    }
    const totalSum = pairSum + oddSum;
    const controlUnit = (10 - (totalSum % 10)) % 10;

    if (calculatedLetter.match(/[ABEH]/)) {
        return control === controlUnit.toString();
    } else if (calculatedLetter.match(/[KPQS]/)) {
        return control === letters[controlUnit];
    } else if (calculatedLetter.match(/[LMXYZ]/)) {
        // Foreign initial letter
        return (
            control === controlUnit.toString() ||
            control === letters[controlUnit]
        );
    }
    return (
        control === controlUnit.toString() || control === letters[controlUnit]
    );
};

const validateNIF = (nif: string | undefined): boolean => {
    if (!nif) return false;

    return validateDNI(nif) || validateCIF(nif);
};

const validateEmail = (email: string | undefined): boolean => {
    if (!email) return false;

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return emailRegex.test(email);
};

addMethod(string, "dni", function () {
    return this.test(
        "dni",
        i18n.t("validation:string.dni"),
        (value) =>
            value === undefined || value.length === 0 || validateDNI(value),
    );
});

addMethod(string, "dniNie", function () {
    return this.test(
        "dniNie",
        i18n.t("validation:string.dni-nie"),
        (value) =>
            value === undefined ||
            value.length === 0 ||
            validateDNI(value) ||
            validateNIE(value),
    );
});

addMethod(string, "nie", function () {
    return this.test(
        "nie",
        i18n.t("validation:string.nie"),
        (value) =>
            value === undefined || value.length === 0 || validateNIE(value),
    );
});

addMethod(string, "cif", function () {
    return this.test(
        "cif",
        i18n.t("validation:string.cif"),
        (value) =>
            value === undefined || value.length === 0 || validateCIF(value),
    );
});

addMethod(string, "nif", function () {
    return this.test(
        "nif",
        i18n.t("validation:string.nif"),
        (value) =>
            value === undefined || value.length === 0 || validateNIF(value),
    );
});

addMethod(string, "nifNie", function () {
    return this.test(
        "nifNie",
        i18n.t("validation:string.nif-nie"),
        (value) =>
            value === undefined ||
            value.length === 0 ||
            validateNIF(value) ||
            validateNIE(value),
    );
});

addMethod(string, "email", function () {
    return this.test(
        "email",
        i18n.t("validation:string.email"),
        (value) =>
            value === undefined || value.length === 0 || validateEmail(value),
    );
});
