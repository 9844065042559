import { FallbackError } from "@core/domain/errors/fallback.error";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CreateCostBody } from "@entity/data/dto/cost/create-cost.body";
import { IVATypeEnumDto } from "@entity/data/dto/iva-type.dto";
import { CreateCost } from "@entity/domain/models/cost/create-cost.model";
import { CostFormValuesValidated } from "@entity/presentation/component/cost-form/cost-form.component";
import { inject, injectable } from "inversify";
import { IdentificationEnumMapper } from "../deposit/identification-enum.mapper";
import { CostTypeEnumMapper } from "./cost-type-enum.mapper";
import { IRPFTypeEnumMapper } from "./irpf-type-enum.mapper";
import { IVAEnumMapper } from "./iva-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type.mapper";

@injectable()
export class CreateCostMapper {
    constructor(
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(IdentificationEnumMapper)
        private readonly identificationEnumMapper: IdentificationEnumMapper,
        @inject(CostTypeEnumMapper)
        private readonly costTypeEnumMapper: CostTypeEnumMapper,
        @inject(IVAEnumMapper)
        private readonly ivaEnumMapper: IVAEnumMapper,
        @inject(IRPFTypeEnumMapper)
        private readonly irpfTypeEnumMapper: IRPFTypeEnumMapper,
    ) {}
    mapFromCostFormValues(
        costFormValuesValidated: CostFormValuesValidated,
        entityId: number,
    ): CreateCost {
        return new CreateCost(
            entityId,
            costFormValuesValidated.invoiceNum,
            costFormValuesValidated.concept,
            costFormValuesValidated.socialReason,
            costFormValuesValidated.identificationType,
            costFormValuesValidated.identification,
            costFormValuesValidated.costType,
            costFormValuesValidated.invoiceDate,
            costFormValuesValidated.paymentDate,
            costFormValuesValidated.paymentMethod,
            costFormValuesValidated.base,
            costFormValuesValidated.iva,
            0,
            costFormValuesValidated.totalCost,
            costFormValuesValidated.irpfType,
            costFormValuesValidated.otherCostType ?? undefined,
            undefined,
            undefined,
            costFormValuesValidated.additionalExpensesToUpdate,
        );
    }

    mapToDto(createCost: CreateCost): CreateCostBody {
        if (!createCost.irpfType) {
            throw new FallbackError();
        }
        const identificationType = this.identificationEnumMapper.mapToDto(
            createCost.issuerIdentificationType,
        );

        const paymentMethod = this.paymentMethodTypeEnumMapper.mapToDto(
            createCost.paymentMethod,
        );

        const type = this.costTypeEnumMapper.mapToDto(createCost.type);

        const iva = this.ivaEnumMapper.mapToDto(createCost.iva);

        const irpf = this.irpfTypeEnumMapper.mapToDto(createCost.irpfType);

        return {
            number: createCost.invoiceNum,
            concept: createCost.concept,
            issuer_name: createCost.issuerName,
            issuer_identification_type: identificationType,
            issuer_identification_number: createCost.identificationNumber,
            type: type,
            others_type: createCost.othersType ?? "",
            issue_date: convertEmptyToUndefined(
                createCost.invoiceDate?.toISO(),
            ),
            payment_date: convertEmptyToUndefined(
                createCost.paymentDate?.toISO(),
            ),
            payment_method: convertEmptyToUndefined(paymentMethod),
            other_payment_method: createCost.otherPaymentMethod ?? "",
            base: createCost.base ?? 0,
            iva: iva ?? IVATypeEnumDto.Cero,
            total: convertEmptyToUndefined(createCost.total),
            discount: createCost.discount ?? 0,
            irpf_type: irpf,
            entity: createCost.entityId,
            document: createCost.document?.id,
        };
    }
}
