import { BeneficiaryIdentificationTypeEnumDto } from "@beneficiary/data/dto/beneficiary-identification-type.dto";
import { CreateRelativesDto } from "@beneficiary/data/dto/relatives/create-relatives.body";
import { CreateRelative } from "@beneficiary/domain/models/relatives/create-relative.model";
import { RelativeFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/relatives/form/relative-form.view";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { inject, injectable } from "inversify";
import { BeneficiaryIdentificationTypeEnumMapper } from "../beneficiary-identification-type-enum.mapper";
import { RelationshipEnumMapper } from "../relationship-enum.mapper";

@injectable()
export class CreateRelativeMapper {
    constructor(
        @inject(RelationshipEnumMapper)
        private readonly relationshipEnumMapper: RelationshipEnumMapper,
        @inject(BeneficiaryIdentificationTypeEnumMapper)
        private readonly beneficiaryIdentificationTypeEnumMapper: BeneficiaryIdentificationTypeEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
        @inject(EmploymentStatusEnumMapper)
        private readonly employmentStatusEnumMapper: EmploymentStatusEnumMapper,
    ) {}
    mapToDto(
        createRelative: CreateRelative,
        beneficiaryId: number,
    ): CreateRelativesDto {
        const relationship = this.relationshipEnumMapper.mapToDto(
            createRelative.relationship,
        );
        const identificationType =
            this.beneficiaryIdentificationTypeEnumMapper.mapToDto(
                createRelative.documentType,
            );
        const levelOfEducation = this.educationLevelEnumMapper.mapToDto(
            createRelative.educationLevel,
        );
        let employmentSituation = undefined;
        if (createRelative.employmentStatus) {
            employmentSituation = this.employmentStatusEnumMapper.mapToDto(
                createRelative.employmentStatus,
            );
        }

        const createRelativeDto: CreateRelativesDto = {
            beneficiary: beneficiaryId,
            parentage: relationship,
            birth_date: createRelative.birthdate.toISODate() ?? "",
            identification_type: identificationType,
            level_of_education: levelOfEducation,
            education_centre: createRelative.courseCenter,
            employment_situation: employmentSituation,
            net_salary: createRelative.netSalary?.toString(),
        };

        if (createRelative.tutorEmail && createRelative.tutorPhone) {
            createRelativeDto.tutelage_email = createRelative.tutorEmail;
            createRelativeDto.tutelage_phone = createRelative.tutorPhone;
        }

        if (
            identificationType !==
            BeneficiaryIdentificationTypeEnumDto.NoDocumentation
        ) {
            createRelativeDto.identification = createRelative.documentId ?? "";
        }

        return createRelativeDto;
    }

    mapFromFormValues(
        relativeForm: RelativeFormValuesValidated,
    ): CreateRelative {
        return new CreateRelative(
            relativeForm.relationship,
            relativeForm.birthdate,
            relativeForm.documentType,
            relativeForm.educationLevel,
            relativeForm.employmentStatus,
            relativeForm.documentId,
            relativeForm.courseCenter,
            relativeForm.tutorPhone,
            relativeForm.tutorEmail,
            relativeForm.netSalary ?? undefined,
        );
    }
}
