import { Relative } from "./relative.model";

export class CreateRelative {
    constructor(
        public relationship: Relative["relationship"],
        public birthdate: Relative["birthdate"],
        public documentType: Relative["documentType"],
        public educationLevel: Relative["educationLevel"],
        public employmentStatus?: Relative["employmentStatus"],
        public documentId?: Relative["documentId"],
        public courseCenter?: Relative["courseCenter"],
        public tutorPhone?: Relative["tutorPhone"],
        public tutorEmail?: Relative["tutorEmail"],
        public netSalary?: Relative["netSalary"],
    ) {}
}
