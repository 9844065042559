import { inject, injectable } from "inversify";
import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { EntitySummaryDto } from "@entity/data/dto/entities-summary.dto";
import { EntitySummary } from "@entity/domain/models/entity-summary.model";
import { CategoryTypeEnumMapper } from "@entity/data/mappers/category-type-enum.mapper";
import { CategoryTypeEnum } from "@entity/domain/models/category-type.enum";
import { Nullish } from "@core/domain/types/nullish.type";

@injectable()
export class EntitySummaryMapper {
    constructor(
        @inject(CategoryTypeEnumMapper)
        private readonly categoryTypeEnumMapper: CategoryTypeEnumMapper,
    ) {}
    map(entitySummaryDto: EntitySummaryDto): Nullable<EntitySummary> {
        const errors = validateSync(entitySummaryDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        let category: Nullish<CategoryTypeEnum> = undefined;

        if (entitySummaryDto.category) {
            category = this.categoryTypeEnumMapper.map(
                entitySummaryDto.category,
            );
        }

        if (category === null) return null;

        return new EntitySummary(
            entitySummaryDto.id,
            entitySummaryDto.company_name,
            entitySummaryDto.identification ?? undefined,
            category,
            entitySummaryDto.category_display_name ?? undefined,
        );
    }
}
