import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpConfig } from "@core/data/infrastructures/http/http-config";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { OrderMapper } from "@core/data/mappers/order.mapper";
import { CreateDocumentError } from "@core/domain/errors/create-document.error";
import { DocumentMaxSizeError } from "@core/domain/errors/document-max-size.error";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CatalogueDto } from "@project/data/dto/catalogue.dto";
import { CreateProjectBody } from "@project/data/dto/create-project.body";
import { EditProjectBody } from "@project/data/dto/edit-project.body";
import {
    ProjectEmployeesDto,
    ProjectEmployeesQuery,
} from "@project/data/dto/project-employee/project-employees.dto";
import { ProjectDto } from "@project/data/dto/project.dto";
import { StatusDto } from "@project/data/dto/status.dto";
import { CatalogueMapper } from "@project/data/mappers/catalogue.mapper";
import { CreateProjectMapper } from "@project/data/mappers/create-project.mapper";
import { EditProjectMapper } from "@project/data/mappers/edit-project.mapper";
import { ProjectEmployeesMapper } from "@project/data/mappers/project-employees.mapper";
import { ProjectMapper } from "@project/data/mappers/project.mapper";
import { StatusMapper } from "@project/data/mappers/status.mapper";
import { Catalogue } from "@project/domain/models/catalogue.model";
import { CreateProject } from "@project/domain/models/create-project.model";
import { EditProject } from "@project/domain/models/edit-project.model";
import {
    ProjectEmployees,
    ProjectEmployeesSearchFilters,
} from "@project/domain/models/project-employees.model";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { ProjectsSummaryTransformer } from "@project/domain/models/projects-summary.model";
import { Status } from "@project/domain/models/status.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { ProjectSearchFilters } from "../../domain/models/project-search-filters";
import { Project } from "../../domain/models/project.model";
import { TerritorialScope } from "../../domain/models/territorial-scope.model";
import {
    ProjectSummaryDto,
    ProjectsSummaryDto,
    ProjectsSummaryQuery,
    projectsSummaryOrderMap,
} from "../dto/project-summary.dto";
import { TerritorialScopeDto } from "../dto/territorial-scope.dto";
import { FinancialEntityTypeEnumMapper } from "../mappers/financial-entity/financial-entity-type-enum.mapper";
import { ProjectsSummaryMapper } from "../mappers/projects-summary.mapper";
import { StatusEnumMapper } from "../mappers/status-enum.mapper";
import { TerritorialScopeMapper } from "../mappers/territorial-scope.mapper";

@injectable()
export class ProjectDatasource {
    // eslint-disable-next-line max-params
    constructor(
        @inject(CatalogueMapper)
        private readonly catalogueMapper: CatalogueMapper,
        @inject(CreateProjectMapper)
        private readonly createProjectMapper: CreateProjectMapper,
        @inject(EditProjectMapper)
        private readonly editProjectMapper: EditProjectMapper,
        @inject(FinancialEntityTypeEnumMapper)
        private readonly financialEntityTypeEnumMapper: FinancialEntityTypeEnumMapper,
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(OrderMapper) private readonly orderMapper: OrderMapper,
        @inject(ProjectEmployeesMapper)
        private readonly projectEmployeesMapper: ProjectEmployeesMapper,
        @inject(ProjectMapper)
        private readonly projectMapper: ProjectMapper,
        @inject(ProjectsSummaryMapper)
        private readonly projectsSummaryMapper: ProjectsSummaryMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(StatusMapper)
        private readonly statusMapper: StatusMapper,
        @inject(TerritorialScopeMapper)
        private readonly territorialScopeMapper: TerritorialScopeMapper,
    ) {}

    async create(
        entityId: number,
        newProject: CreateProject,
    ): Promise<Either<FallbackError | ValidationError, Project>> {
        const createdProjectDto = this.createProjectMapper.mapToDto(
            entityId,
            newProject,
        );

        const createProjectResult = await this.http.post<
            ProjectDto,
            CreateProjectBody
        >("/projects/", createdProjectDto);

        return createProjectResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const createdProject = this.projectMapper.map(
                    plainToClass(ProjectDto, response.data),
                );

                if (!createdProject) return Either.Left(new FallbackError());

                return Either.Right(createdProject);
            });
    }

    async fetchBy(
        pagination: Pagination,
        filters?: ProjectSearchFilters,
        order?: Order<ProjectSummary>,
    ): Promise<Either<FallbackError, ProjectsSummaryTransformer>> {
        const query: ProjectsSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        const YEARS_LENGTH = 4;

        if (filters?.name) query.search = filters.name;
        if (filters?.statusProject)
            query.status = this.statusEnumMapper.mapToDto(
                filters.statusProject,
            );

        if (filters?.statusIn) {
            // filter to get projects by more than one status
            const statusIn = [] as string[];
            filters.statusIn.map((status) =>
                statusIn.push(this.statusEnumMapper.mapToDto(status)),
            );
            query.status_in = statusIn.join(",");
        }

        if (filters?.financialEntityType)
            query.finantial_entity_type =
                this.financialEntityTypeEnumMapper.mapToDto(
                    filters.financialEntityType,
                );
        if (filters?.entityIds) query.entity = filters.entityIds.join(",");
        if (filters?.activeFromDate)
            query.active_from_date = filters.activeFromDate.toISODate();
        if (filters?.activeToDate)
            query.active_to_date = filters.activeToDate.toISODate();
        if (filters?.years)
            query.years = filters.years
                .map((year) => year.toISODate()?.slice(0, YEARS_LENGTH))
                .join(",");
        if (filters?.materials) query.materials = filters.materials.join(",");

        if (filters?.collectiveIds)
            query.collectives = filters.collectiveIds.join(",");

        if (filters?.territorialScopeIds)
            query.territorial_scope = filters.territorialScopeIds.join(",");

        if (filters?.isActive) query.is_active = filters.isActive;

        if (order) {
            const orderQuery = this.orderMapper.mapToDto<
                ProjectSummary,
                ProjectSummaryDto
            >(order.field, order.direction, projectsSummaryOrderMap);

            if (orderQuery) {
                query.ordering = orderQuery;
            }
        }

        const projectsResult = await this.http.get<ProjectsSummaryDto>(
            "/projects/",
            {
                query,
            },
        );

        return projectsResult
            .map((response) =>
                this.projectsSummaryMapper.mapToTransformer(
                    plainToClass(ProjectsSummaryDto, response.data),
                ),
            )
            .mapLeft(() => new FallbackError());
    }

    async fetchAllCatalogues(): Promise<Either<FallbackError, Catalogue[]>> {
        const responseResult = await this.http.get<CatalogueDto[]>(
            "/projects/catalogue/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((catalogueDto) =>
                    this.catalogueMapper.map(
                        plainToClass(CatalogueDto, catalogueDto),
                    ),
                ),
            );
    }

    async fetchAllStatus(): Promise<Either<FallbackError, Status[]>> {
        const responseResult =
            await this.http.get<StatusDto[]>("/projects/status/");

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((statusDto) =>
                    this.statusMapper.map(plainToClass(StatusDto, statusDto)),
                ),
            );
    }

    async fetchAllTerritorialScopes(): Promise<
        Either<FallbackError, TerritorialScope[]>
    > {
        const responseResult = await this.http.get<TerritorialScopeDto[]>(
            "/projects/territorial_scope/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.territorialScopeMapper.map(
                        plainToClass(TerritorialScopeDto, typeDto),
                    ),
                ),
            );
    }

    async fetchAllProjectEmployeesBy(
        filters?: ProjectEmployeesSearchFilters,
    ): Promise<Either<FallbackError, ProjectEmployees>> {
        const query: ProjectEmployeesQuery = {};

        if (filters?.projectId) {
            query.project = filters.projectId;
        }
        const projectEmployeesResult = await this.http.get<ProjectEmployeesDto>(
            "/projects_employees/",
            {
                query,
            },
        );

        return projectEmployeesResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.projectEmployeesMapper.map(
                    plainToClass(ProjectEmployeesDto, response.data),
                ),
            );
    }

    async fetchById(
        projectId: number,
    ): Promise<Either<FallbackError, Project>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const projectResult = await this.http.get<ProjectDto>(
            `/projects/${projectId}/`,
            {
                query,
            },
        );

        return projectResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const project = this.projectMapper.map(
                    plainToClass(ProjectDto, response.data),
                );

                if (!project) return Either.Left(new FallbackError());

                return Either.Right(project);
            });
    }

    async edit(
        entityId: number,
        projectToEdit: EditProject,
    ): Promise<Either<FallbackError | ValidationError, Project>> {
        const editProjectBody = this.editProjectMapper.mapToDto(
            entityId,
            projectToEdit,
        );

        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };

        const editProjectResult = await this.http.patch<
            ProjectDto,
            EditProjectBody
        >(`/projects/${projectToEdit.id}/update_nested/`, editProjectBody, {
            query,
        });

        return editProjectResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const project = this.projectMapper.map(
                    plainToClass(ProjectDto, response.data),
                );

                if (!project) return Either.Left(new FallbackError());

                return Either.Right(project);
            });
    }

    async importProjects(
        projectsImport: CreateDocument,
        entityId: number,
    ): Promise<Either<CreateDocumentError, boolean>> {
        const formData: FormData = new FormData();
        const config: HttpConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        formData.append("csv_file", projectsImport.file);
        formData.append("entity", entityId.toString());

        const importedProjectsResult = await this.http.post<boolean>(
            "/projects/import_projects_from_csv/",
            formData,
            config,
        );

        return importedProjectsResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                } else if (error instanceof HttpFailedRequestError) {
                    return new DocumentMaxSizeError();
                }

                return new FallbackError();
            })
            .flatMap(() => Either.Right(true));
    }

    async exportProjects(
        entityId: number,
    ): Promise<Either<FallbackError, Blob>> {
        const query: ProjectsSummaryQuery = {
            entity: entityId.toString(),
        };
        const projectsResult = await this.http.get<Blob>(
            `/projects/export_projects_csv/`,
            {
                responseType: "blob",
                query,
            },
        );

        return projectsResult
            .mapLeft(() => new FallbackError())
            .map((response) => response.data as Blob);
    }
}
