import { AssociateServiceToDependenceEnumDto } from "@beneficiary/data/dto/associate-service-to-dependence.enum.dto";
import { DependenceGradeEnumDto } from "@beneficiary/data/dto/dependence-grade-enum.dto";
import { DisabilityTypeEnumDto } from "@beneficiary/data/dto/disability-type-enum.dto";
import { ExpenseRecurrenceEnumDto } from "@beneficiary/data/dto/expense-recurrence.dto";
import { ExpenseTypeEnumDto } from "@beneficiary/data/dto/expense-type.dto";
import { TherapyTypeEnumDto } from "@beneficiary/data/dto/therapy-type-enum.dto";
import { CountryEnumDto } from "@core/data/dto/country.dto";
import { DocumentDto } from "@core/data/dto/document.dto";
import { EducationLevelEnumDto } from "@core/data/dto/education-level.dto";
import { EmploymentStatusEnumDto } from "@core/data/dto/employment-status.dto";
import { MaritalStatusEnumDto } from "@core/data/dto/marital-status-enum.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEmail,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { AddressDto } from "./address.dto";
import { BeneficiaryIdentificationTypeEnumDto } from "./beneficiary-identification-type.dto";
import { DiagnosisTypeEnumDto } from "./diagnosis-type.dto";
import { EmploymentSituationRelevantCharacteristicsEnumDto } from "./employment-situation-relevant-characteristics.dto";
import { GenderEnumDto } from "./gender.dto";
import { RelativesDto } from "./relatives/relatives.dto";

export class ExpenseDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsOptional()
    created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    modified!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    concept!: string;

    @IsString()
    @IsNotEmpty()
    amount!: string;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type!: ExpenseTypeEnumDto;

    @IsString()
    @IsOptional()
    other_type!: Nullable<string>;

    @IsEnum(ExpenseRecurrenceEnumDto)
    @IsNotEmpty()
    recurrence!: ExpenseRecurrenceEnumDto;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;
}

export class ExpensesDto extends PaginatedDto {
    @IsArray()
    @Type(() => ExpenseDto)
    results!: ExpenseDto[];
}

export type ExpensesQuery = PaginatedQuery & {
    beneficiary_id?: Undefinable<number>;
};

export class IncomeDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsOptional()
    created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    modified!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsString()
    @IsOptional()
    net_salary!: Nullable<number>;

    @IsString()
    @IsOptional()
    socioeconomic_benefit!: Nullable<number>;

    @IsString()
    @IsOptional()
    family_unit_income!: Nullable<number>;

    @IsString()
    @IsOptional()
    observations!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;

    @IsString()
    @IsOptional()
    others!: Nullable<number>;
}

export class IncomesDto extends PaginatedDto {
    @IsArray()
    @Type(() => IncomeDto)
    results!: IncomeDto[];
}

export interface IncomeSearchFilters {
    beneficiaryId?: Undefinable<number>;
}

export type IncomesQuery = PaginatedQuery & {
    beneficiary_id?: Undefinable<number>;
};

export class BeneficiaryIdentificationDto {
    @IsNotEmpty()
    @IsNumber()
    id!: number;

    @IsDateString()
    @IsOptional()
    created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    modified!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsEnum(BeneficiaryIdentificationTypeEnumDto)
    identification_type!: BeneficiaryIdentificationTypeEnumDto;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    identification_expiration!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    beneficiary!: number;
}

export class BeneficiaryIdentificationResultDto extends BeneficiaryIdentificationDto {
    @IsArray()
    @Type(() => BeneficiaryIdentificationDto)
    results!: BeneficiaryIdentificationDto[];
}

export class BeneficiaryDto {
    @IsNotEmpty()
    @IsNumber()
    id!: number;

    @IsNotEmpty()
    @IsString()
    name!: string;

    @IsNotEmpty()
    @IsString()
    surname!: string;

    @IsEnum(GenderEnumDto)
    @IsNotEmpty()
    gender!: GenderEnumDto;

    @IsString()
    @IsOptional()
    phone!: Nullable<string>;

    @IsString()
    @IsOptional()
    phone_owner!: Nullable<string>;

    @IsEmail()
    @IsOptional()
    email!: Nullable<string>;

    @IsString()
    @IsOptional()
    email_owner!: Nullable<string>;

    @IsEnum(CountryEnumDto)
    @IsNotEmpty()
    nationality!: CountryEnumDto;

    @IsEnum(CountryEnumDto)
    @IsNotEmpty()
    country_of_origin!: CountryEnumDto;

    @IsDateString()
    @IsNotEmpty()
    birth_date!: Nullable<string>;

    @IsEnum(MaritalStatusEnumDto)
    @IsNotEmpty()
    marital_status!: MaritalStatusEnumDto;

    @IsEnum(EducationLevelEnumDto)
    @IsNotEmpty()
    level_of_education!: EducationLevelEnumDto;

    @IsString()
    @IsOptional()
    complementary_training!: Nullable<string>;

    @IsEnum(EmploymentStatusEnumDto)
    @IsNotEmpty()
    employment_situation!: EmploymentStatusEnumDto;

    @IsArray()
    @IsString({ each: true })
    @IsNotEmpty()
    // eslint-disable-next-line id-length
    employment_situation_relevant_characteristics!: Nullable<
        EmploymentSituationRelevantCharacteristicsEnumDto[]
    >;

    @IsString()
    @IsOptional()
    // eslint-disable-next-line id-length
    other_employment_situation_relevant_characteristics!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    intervention_date!: Nullable<string>;

    @IsBoolean()
    disability!: boolean;

    @IsEnum(DisabilityTypeEnumDto, { each: true })
    @IsOptional()
    disability_types!: Nullable<DisabilityTypeEnumDto[]>;

    @IsString()
    @IsOptional()
    health_observations!: Nullable<string>;

    @IsBoolean()
    has_dependence!: boolean;

    @IsEnum(DependenceGradeEnumDto)
    @IsOptional()
    dependence_grade!: Nullable<DependenceGradeEnumDto>;

    @IsBoolean()
    in_therapy!: boolean;

    @IsEnum(TherapyTypeEnumDto)
    @IsOptional()
    therapy_type!: Nullable<TherapyTypeEnumDto>;

    @IsArray()
    @IsOptional()
    @Type(() => RelativesDto)
    family_members!: RelativesDto[];

    @IsBoolean()
    @IsNotEmpty()
    under_protection!: boolean;

    @IsBoolean()
    @IsNotEmpty()
    has_legal_guardian!: boolean;

    @IsNumber()
    @IsOptional()
    usual_address!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    census_address!: Nullable<number>;

    @IsOptional()
    @Type(() => AddressDto)
    usual_address_data!: Nullable<AddressDto>;

    @IsOptional()
    @Type(() => AddressDto)
    census_address_data!: Nullable<AddressDto>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => ExpenseDto)
    expenses!: Nullable<ExpenseDto[]>;

    @IsArray()
    @IsString({ each: true })
    @IsOptional()
    projects!: string[];

    @IsOptional()
    @Type(() => IncomeDto)
    income!: Nullable<IncomeDto>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsOptional()
    @IsEnum(AssociateServiceToDependenceEnumDto)
    associate_service_to_dependence!: Nullable<AssociateServiceToDependenceEnumDto>;

    @IsString()
    dependence_diagnosis!: string;

    @IsOptional()
    @IsString()
    dependence_observations!: Nullable<string>;

    @IsBoolean()
    is_diagnosed!: boolean;

    @IsEnum(DiagnosisTypeEnumDto)
    @IsOptional()
    diagnosis_type!: DiagnosisTypeEnumDto;

    @IsDateString()
    @IsOptional()
    diagnosis_date!: Nullable<string>;
}
