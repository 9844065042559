import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import { IRPFType } from "@entity/domain/models/cost/irpf-type.model";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { PaymentMethodType } from "@entity/domain/models/cost/payment-method-type.model";
import { inject, injectable } from "inversify";
import {
    CostSearchFilters,
    CostSummary,
    CostsSummary,
} from "../../domain/models/cost/cost-summary.model";
import {
    AdditionalExpense,
    AdditionalExpenseCreate,
    Cost,
} from "../../domain/models/cost/cost.model";
import { CreateCost } from "../../domain/models/cost/create-cost.model";
import { EditCost } from "../../domain/models/cost/edit-cost.model";
import { CostDatasource } from "../datasources/cost.datasource";

@injectable()
export class CostRepository {
    constructor(
        @inject(CostDatasource)
        private readonly costDatasource: CostDatasource,
    ) {}

    async getBy(
        pagination: Pagination,
        filters?: CostSearchFilters,
    ): Promise<Either<FallbackError, CostsSummary>> {
        const costsSummary = await this.costDatasource.fetchAllBy(
            pagination,
            filters,
        );

        return costsSummary.mapLeft(() => new FallbackError());
    }

    async getAllBy(
        filters?: CostSearchFilters,
    ): Promise<Either<FallbackError, CostSummary[]>> {
        const costsResult = await this.getBy(
            Pagination.NoPagination(),
            filters,
        );

        return costsResult.map((costs) => costs.costsSummary);
    }

    async getById(costId: number): Promise<Either<FallbackError, Cost>> {
        return this.costDatasource.fetchById(costId);
    }

    async create(
        createCost: CreateCost,
    ): Promise<Either<ValidationError | FallbackError, Cost>> {
        const createdCostResult = await this.costDatasource.create(createCost);
        const createdCost = createdCostResult.fold(
            () => null,
            (cost) => cost,
        );
        if (createdCost && createCost.additionalExpensesToUpdate) {
            await Promise.all(
                createCost.additionalExpensesToUpdate.map(
                    async (additionalExpense) =>
                        this.createAdditionalExpense(additionalExpense),
                ),
            );
        }
        return createdCostResult;
    }

    async createAdditionalExpense(
        additionalExpense: AdditionalExpenseCreate,
    ): Promise<void> {
        const createAdditionalExpenses =
            await this.costDatasource.createAdditionalExpenses(
                additionalExpense,
            );
        const createdAdditionalExpenses = createAdditionalExpenses.fold(
            () => null,
            (additionalExp) => additionalExp,
        );

        if (!createdAdditionalExpenses) {
            throw new FallbackError();
        }
    }

    async updateAdditionalExpense(
        additionalExpense: AdditionalExpenseCreate,
    ): Promise<Either<ValidationError | FallbackError, AdditionalExpense>> {
        return this.costDatasource.updateAdditionalExpenses(additionalExpense);
    }
    async update(
        editCost: EditCost,
    ): Promise<Either<ValidationError | FallbackError, Cost>> {
        const updatedCostResult = await this.costDatasource.update(editCost);
        const updatedCost = updatedCostResult.fold(
            () => null,
            (cost) => cost,
        );
        if (editCost.additionalExpensesToUpdate && updatedCost) {
            const additionalExpensesToCreate =
                editCost.additionalExpensesToUpdate.filter(
                    (additionalExpense) => !additionalExpense.id,
                );
            if (additionalExpensesToCreate.isNotEmpty())
                await Promise.all(
                    additionalExpensesToCreate.map(
                        async (additionalExpenseToCreate) =>
                            this.createAdditionalExpense(
                                additionalExpenseToCreate,
                            ),
                    ),
                );

            const deleteIds = updatedCost.additionalExpenses?.filter(
                (additionalExpense) =>
                    !editCost.additionalExpensesToUpdate
                        ?.map(
                            (additionalExpenseToUpdate) =>
                                additionalExpenseToUpdate.id,
                        )
                        .includes(additionalExpense.id),
            );

            if (deleteIds)
                await Promise.all(
                    deleteIds.map(async (deleteAdditional) =>
                        this.deleteAdditionalExpense(deleteAdditional.id),
                    ),
                );
        }
        return updatedCostResult;
    }

    async delete(costId: number): Promise<Either<FallbackError, boolean>> {
        return this.costDatasource.delete(costId);
    }

    async deleteAdditionalExpense(
        additionalExpenseId: number,
    ): Promise<Either<FallbackError, boolean>> {
        return this.costDatasource.deleteAdditionalExpense(additionalExpenseId);
    }

    async getAllPaymentMethodTypes(): Promise<
        Either<FallbackError, PaymentMethodType[]>
    > {
        return this.costDatasource.fetchAllPaymentMethod();
    }

    async getAllIvaTypes(): Promise<Either<FallbackError, IVAType[]>> {
        return this.costDatasource.fetchAllIvaTypes();
    }

    async getAllCostsTypes(): Promise<Either<FallbackError, CostType[]>> {
        return this.costDatasource.fetchAllCostsTypes();
    }

    async getAllIrfpTypes(): Promise<Either<FallbackError, IRPFType[]>> {
        return this.costDatasource.fetchAllIrpfTypes();
    }
}
