export enum DepositTypeEnum {
    PRIVATE_GRANT = "private_grant",
    FUNDING_AGENCY = "funding_agency",
    DONATION = "donation",
    FUNDRAISING_CAMPAIGN = "fundraising_campaign",
    PUBLIC_ADMINISTRATION_CONTRACT = "public_dministration_contract",
    PRIVATE_ENTITY_CONTRACT = "private_entity_contract",
    MERCH = "merch",
    MEMBERSHIP_FEES = "membership_fees",
    OTHER = "other",
}

export class DepositTypes {
    constructor(
        public readonly id: DepositTypeEnum,
        public readonly label: string,
    ) {}
}
