import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { TechnicalProposalMapper } from "@project/data/mappers/technical-proposal/technical-proposal.mapper";
import { TechnicalProposal } from "@project/domain/models/technical-proposals.model";
import { CreateTechnicalProposalUseCase } from "@project/domain/usecases/technical-proposal/create-technical-proposal.usecase";
import { EditTechnicalProposalUseCase } from "@project/domain/usecases/technical-proposal/edit-technical-proposal.usecase";
import { ExportBeneficiaryTechProposalByUseCase } from "@project/domain/usecases/technical-proposal/export-beneficiary-tech-proposal-by.usecase";
import { ExportTechnicalProposalUseCase } from "@project/domain/usecases/technical-proposal/export-technical-proposal.usecase";
import { GetAllTechnicalProposalsByUseCase } from "@project/domain/usecases/technical-proposal/get-all-technical-proposal-by.usecase";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";
import { TechnicalProposalFormValuesValidated } from "./technical-proposal-form.component";

export interface DocumentTable {
    id: number;
    name: string;
}

@injectable()
export class TechnicalProposalTabViewModel extends BaseViewModel {
    _projectId: Undefinable<number> = undefined;

    @observable
    initialLoading = true;

    @observable
    technicalProposal: Undefinable<TechnicalProposal> = undefined;

    constructor(
        @inject(ExportTechnicalProposalUseCase)
        private readonly exportTechnicalProposalUseCase: ExportTechnicalProposalUseCase,
        @inject(ExportBeneficiaryTechProposalByUseCase)
        private readonly exportBeneficiaryTechProposalByUseCase: ExportBeneficiaryTechProposalByUseCase,
        @inject(GetAllTechnicalProposalsByUseCase)
        private readonly getAllTechnicalProposalsByUseCase: GetAllTechnicalProposalsByUseCase,
        @inject(CreateTechnicalProposalUseCase)
        private readonly createTechnicalProposalUseCase: CreateTechnicalProposalUseCase,
        @inject(EditTechnicalProposalUseCase)
        private readonly editTechnicalProposalUseCase: EditTechnicalProposalUseCase,
        @inject(TechnicalProposalMapper)
        private readonly technicalProposalMapper: TechnicalProposalMapper,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        if (this._projectId) {
            this.technicalProposal = await this.getTechnicalProposalByProject();
        }

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getTechnicalProposalByProject(): Promise<
        Undefinable<TechnicalProposal>
    > {
        if (this._projectId) {
            const technicalProposal =
                await this.getAllTechnicalProposalsByUseCase.execute({
                    projectId: this._projectId,
                });
            if (technicalProposal.length) return technicalProposal[0];
        }
        return undefined;
    }

    async reloadTechnicalProposal(): Promise<void> {
        this.technicalProposal = await this.getTechnicalProposalByProject();
    }

    async exportTechnicalProposal(name: string): Promise<void> {
        if (!this.technicalProposal?.id) return;

        LoadLayoutStore.start();

        const blob = await this.exportTechnicalProposalUseCase.execute(
            this.technicalProposal.id,
        );

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `Memoria Técnica (Marco Lógico) - ${name}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }

    async exportBeneficiaryTechProposal(name: string): Promise<void> {
        if (!this.technicalProposal?.id) return;

        LoadLayoutStore.start();

        const blob = await this.exportBeneficiaryTechProposalByUseCase.execute({
            projectIds: [this.technicalProposal.id],
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Beneficiarios - ${name}.xlsx`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }

    async createTechnicalProposal(
        technicalProposalForm: TechnicalProposalFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        technicalProposalForm.projectId = this._projectId;

        const createResult = await this.createTechnicalProposalUseCase.execute(
            this.technicalProposalMapper.mapFromFormToCreate(
                technicalProposalForm,
            ),
        );

        if (createResult) ToastManagerStore.success();

        LoadLayoutStore.finish();
    }

    async editTechnicalProposal(
        technicalProposal: TechnicalProposalFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const editResult = await this.editTechnicalProposalUseCase.execute(
            this.technicalProposalMapper.mapFromFormToEdit(technicalProposal),
        );

        if (editResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
