import { EditRegisteredRecordBody } from "@entity/data/dto/registered-record/edit-registered-record.body";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import { EditRegisteredRecord } from "@entity/domain/models/registered-record/edit-registered-record.model";
import { RegisteredRecordFormValuesValidated } from "@entity/presentation/component/entity-form/general/registered-record/form/registered-record-form";
import { inject, injectable } from "inversify";

@injectable()
export class EditRegisteredRecordMapper {
    constructor(
        @inject(AdministrativeRecordScopeEnumMapper)
        private readonly administrativeRecordScopeEnumMapper: AdministrativeRecordScopeEnumMapper,
    ) {}

    mapToDto(
        editRegisteredRecord: EditRegisteredRecord,
    ): EditRegisteredRecordBody {
        const registeredAdministrativeRecordScope =
            this.administrativeRecordScopeEnumMapper.mapToDto(
                editRegisteredRecord.registeredAdministrativeRecordScope,
            );

        const editRegisteredRecordBody: EditRegisteredRecordBody = {
            id: editRegisteredRecord.id,
            registered_administrative_record_scope:
                registeredAdministrativeRecordScope,
            registered_record_name: editRegisteredRecord.registeredRecordName,
            record_identification_code:
                editRegisteredRecord.recordIdentificationCode,
            entity: editRegisteredRecord.entity,
        };

        return editRegisteredRecordBody;
    }

    mapFromModal(
        id: number,
        entityId: number,
        registeredRecord: RegisteredRecordFormValuesValidated,
    ): EditRegisteredRecord {
        const editRegisteredRecord: EditRegisteredRecord =
            new EditRegisteredRecord(
                id,
                registeredRecord.registeredAdministrativeRecordScope,
                registeredRecord.registeredRecordName,
                registeredRecord.recordIdentificationCode,
                entityId,
            );
        return editRegisteredRecord;
    }
}
