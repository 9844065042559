export enum CostPeriodicityTypeEnum {
    ANNUAL = "annual",
    JOURNEY = "journey",
    SESSION = "session",
    MONTHLY = "monthly",
}

export class CostPeriodicityType {
    constructor(
        public readonly type: CostPeriodicityTypeEnum,
        public readonly label: string,
    ) {}
}
