import { CreateDocument } from "@core/domain/models/create-document.model";
import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Collective } from "@entity/domain/models/collective.model";
import { Material } from "@entity/domain/models/material/material.model";
import { GetAllCollectivesUseCase } from "@entity/domain/usecases/get-all-collectives.usecase";
import { FinancialEntityType } from "@project/domain/models/financial-entity/financial-entity-type";
import type { ProjectSearchFilters } from "@project/domain/models/project-search-filters";
import { ProjectsSummary } from "@project/domain/models/projects-summary.model";
import { Status, StatusEnum } from "@project/domain/models/status.model";
import { TerritorialScope } from "@project/domain/models/territorial-scope.model";
import { ExportProjectsUseCase } from "@project/domain/usecases/export-projects.usecase";
import { GetAllFinancialEntityTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financial-entity-types.usecase";
import { GetAllMaterialsUseCase } from "@project/domain/usecases/get-all-materials.usecase";
import { GetAllStatusUseCase } from "@project/domain/usecases/get-all-status.usecase";
import { GetAllTerritorialScopeUseCase } from "@project/domain/usecases/get-all-territorial-scope.usecase";
import { ImportProjectsUseCase } from "@project/domain/usecases/import-projects.usecase";
import { SearchProjectsByUseCase } from "@project/domain/usecases/search-projects-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
export interface ProjectListTable {
    id: number;
    name: string;
    status: string;
}
interface ProjectsListTable {
    projects: ProjectListTable[];
    count: number;
}

const PROJECT_PHASE_3 = 3;
@injectable()
export class ProjectTabViewModel extends BaseViewModel {
    entityId: Nullable<number> = null;

    @observable
    private _projects: ProjectsSummary = new ProjectsSummary([], 0);

    @observable
    status: Status[] = [];

    @observable
    materials: Material[] = [];

    @observable
    financialEntityTypes: FinancialEntityType[] = [];

    @observable
    collectives: Collective[] = [];

    @observable
    territorialScopes: TerritorialScope[] = [];

    @observable
    initialLoading: boolean = true;

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: ProjectSearchFilters = {
        name: "",
        statusProject: null,
        years: null,
        materials: null,
    };

    @observable
    filters: ProjectSearchFilters = this.initialFiltersValue;

    @computed
    get projectsTable(): ProjectsListTable {
        return {
            count: this._projects.count,
            projects: this._projects.projectsSummary.map((projectSummary) => ({
                id: projectSummary.id,
                name: projectSummary.name,
                status: projectSummary.statusLabel,
            })),
        };
    }

    constructor(
        @inject(GetAllMaterialsUseCase)
        private readonly getAllMaterialsUseCase: GetAllMaterialsUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusUseCase: GetAllStatusUseCase,
        @inject(GetAllCollectivesUseCase)
        private readonly getAllCollectivesUsecase: GetAllCollectivesUseCase,
        @inject(GetAllFinancialEntityTypesUsecase)
        private readonly getFinancialEntityTypesUseCase: GetAllFinancialEntityTypesUsecase,
        @inject(GetAllTerritorialScopeUseCase)
        private readonly getAllTerritorialScopeUseCase: GetAllTerritorialScopeUseCase,
        @inject(SearchProjectsByUseCase)
        private readonly searchProjectsByUseCase: SearchProjectsByUseCase,
        @inject(ImportProjectsUseCase)
        private readonly importProjectsUseCase: ImportProjectsUseCase,
        @inject(ExportProjectsUseCase)
        private readonly exportProjectsUseCase: ExportProjectsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllStatus(),
            this.getMaterials(),
            this.getFinancialEntityTypes(),
            this.getCollectives(),
            this.getTerritorialScopes(),
            this.searchProjectsBy(this.filters, this.pagination),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchProjectsBy(this.filters, this.pagination);
    }

    @action
    setFilters(filters: ProjectSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchProjectsBy(this.filters, this.pagination);
    }

    async importProjects(projectsImport: CreateDocument): Promise<boolean> {
        if (!this.entityId) throw Error("Missing entityId");

        LoadLayoutStore.start();

        const projectsImported = await this.importProjectsUseCase.execute(
            projectsImport,
            this.entityId,
        );

        if (projectsImported) {
            await this.reloadProjects();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return projectsImported;
    }

    async exportProjects(): Promise<void> {
        if (!this.entityId) throw Error("Missing entityId");

        LoadLayoutStore.start();

        const blob = await this.exportProjectsUseCase.execute(this.entityId);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Proyectos.csv`);
        document.body.appendChild(link);
        link.click();

        LoadLayoutStore.finish();
    }

    async searchProjectsBy(
        filters: ProjectSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        this.filters.statusIn = [
            // we did this to only get from phase 3 onwards
            StatusEnum.Execution,
            StatusEnum.Justification,
            StatusEnum.Finished,
            StatusEnum.Discarded,
            StatusEnum.Founding,
        ];
        const projects = await this.searchProjectsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._projects = projects;
        });
    }

    async getAllStatus(): Promise<void> {
        const status = await this.getAllStatusUseCase.execute();

        runInAction(() => {
            this.status = status.filter(
                (statusVal) => parseInt(statusVal.id) >= PROJECT_PHASE_3,
            );
        });
    }

    async getMaterials(): Promise<void> {
        const materials = await this.getAllMaterialsUseCase.execute();

        runInAction(() => {
            this.materials = materials;
        });
    }

    async getFinancialEntityTypes(): Promise<void> {
        const financialEntityTypes =
            await this.getFinancialEntityTypesUseCase.execute();

        runInAction(() => {
            this.financialEntityTypes = financialEntityTypes;
        });
    }

    async getCollectives(): Promise<void> {
        const collectives = await this.getAllCollectivesUsecase.execute();

        runInAction(() => {
            this.collectives = collectives;
        });
    }

    async getTerritorialScopes(): Promise<void> {
        const territorialScopes =
            await this.getAllTerritorialScopeUseCase.execute();

        runInAction(() => {
            this.territorialScopes = territorialScopes;
        });
    }

    async reloadProjects(): Promise<void> {
        await this.searchProjectsBy(this.filters, this.pagination);
    }

    @action
    setEntityId(entityId: number): void {
        this.entityId = entityId;
    }
}
