import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { EditBeneficiary } from "@beneficiary/domain/models/edit-beneficiary.model";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { inject, injectable } from "inversify";

@injectable()
export class EditBeneficiaryUseCase {
    constructor(
        @inject(BeneficiaryRepository)
        private readonly beneficiaryRepository: BeneficiaryRepository,
    ) {}

    async execute(beneficiary: EditBeneficiary): Promise<boolean> {
        const editResult = await this.beneficiaryRepository.edit(beneficiary);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.isRight();
    }
}
