import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class ContractTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    constructor() {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        runInAction(() => {
            this.initialLoading = false;
        });
    }
}
