import { EducationLevelEnum } from "@core/domain/models/education-level.model";
import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { BeneficiaryIdentificationTypeEnum } from "../identification-document-type.model";
import { RelationshipEnum } from "../relationship.model";

export class RelativesSearchFilters {
    search?: Nullish<string>;
    beneficiaryId: Nullish<number>;
}

export class Relative {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    static LEGAL_AGE_TO_WORK = 16;
    constructor(
        public readonly id: number,
        public relationship: RelationshipEnum,
        public birthdate: DateTime,
        public educationLevel: EducationLevelEnum,
        public documentType: BeneficiaryIdentificationTypeEnum,
        public employmentStatus: Nullable<EmploymentStatusEnum> = null,
        public documentId?: Nullable<string>,
        public courseCenter?: Undefinable<string>,
        public tutorEmail?: Undefinable<string>,
        public tutorPhone?: Undefinable<string>,
        public netSalary?: Undefinable<number>,
    ) {}
}
