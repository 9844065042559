export enum CostTypeEnum {
    freelance = "freelance",
    supply = "supply",
    local = "local",
    activity = "activity",
    maintenance = "maintenance",
    supplier = "supplier",
    benefit_INVOICE = "benefit_INVOICE",
    info_INVOICE = "info_INVOICE",
    license = "license",
    furniture = "furniture",
    fungible = "fungible",
    admin = "admin",
    audit = "audit",
    isos = "isos",
    other = "other",
}

export class CostType {
    constructor(
        public readonly id: CostTypeEnum,
        public readonly label: string,
    ) {}
}
