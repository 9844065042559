import { LoginUseCase } from "@authentication/domain/usecases/login.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { ObjectSchema, object, string } from "yup";

export interface LoginFormValues {
    email: string;
    password: string;
}

@injectable()
export class LoginPageViewModel extends BaseViewModel {
    initialValues: LoginFormValues = {
        email: "",
        password: "",
    };

    validationSchema: ObjectSchema<LoginFormValues> = object({
        email: string().email().required(),
        password: string().required(),
    });

    constructor(
        @inject(LoginUseCase) private readonly doLoginUseCase: LoginUseCase,
    ) {
        super();
    }

    async submitLogin(values: LoginFormValues): Promise<boolean> {
        LoadLayoutStore.start();

        const loginResult = await this.doLoginUseCase.execute(
            values.email,
            values.password,
        );

        LoadLayoutStore.finish();

        return loginResult;
    }
}
