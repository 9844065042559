import { AssociateServiceToDependence } from "@beneficiary/domain/models/associate-service-to-dependence.enum";
import { DependenceGrade } from "@beneficiary/domain/models/dependence-grade.enum";
import { DisabilityType } from "@beneficiary/domain/models/disability-type.enum";
import { EmploymentRelevantCharacteristics } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Gender } from "@beneficiary/domain/models/gender.model";
import { BeneficiaryIdentificationType } from "@beneficiary/domain/models/identification-document-type.model";
import { MaritalStatus } from "@beneficiary/domain/models/marital-status.model";
import { TherapyType } from "@beneficiary/domain/models/therapy-type.enum";
import { GetAssociateServiceToDependenceUseCase } from "@beneficiary/domain/usecases/get-all-associate-service-to-dependence.usecase";
import { GetAllBeneficiaryIdentificationTypesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiary-identification-types.usecase";
import { GetCountriesUseCase } from "@beneficiary/domain/usecases/get-all-countries.usecase";
import { GetDependenceGradesUseCase } from "@beneficiary/domain/usecases/get-all-dependence-grades.usecase";
import { GetAllDiagnosisTypesUseCase } from "@beneficiary/domain/usecases/get-all-diagnosis-types.usecase";
import { GetDisabilityTypesUseCase } from "@beneficiary/domain/usecases/get-all-disability-types.usecase";
import { GetAllEmploymentCharacteristicsUseCase } from "@beneficiary/domain/usecases/get-all-employment-characteristics.usecase";
import { GetAllGendersUseCase } from "@beneficiary/domain/usecases/get-all-genders.usecase";
import { GetMaritalStatusesUseCase } from "@beneficiary/domain/usecases/get-all-marital-statuses.usecase";
import { GetTherapyTypesUseCase } from "@beneficiary/domain/usecases/get-all-therapy-types.usecase";
import { Country } from "@core/domain/models/country.model";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { DiagnosisType } from "@core/domain/models/diagnosis-type.model";
import { DocumentCategory } from "@core/domain/models/documents-category.model";
import { EducationLevel } from "@core/domain/models/education-level.model";
import { EmploymentStatus } from "@core/domain/models/employment-status.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { GetAllDocumentCategoriesUseCase } from "@core/domain/usecases/get-all-document-categories.usecase";
import { GetAllEducationLevelsUseCase } from "@core/domain/usecases/get-all-education-levels.usecase";
import { GetAllEmploymentStatusUseCase } from "@core/domain/usecases/get-all-employment-status.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable, observable, runInAction } from "mobx";

@injectable()
export class GeneralViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    genders: Gender[] = [];

    @observable
    identificationDocumentTypes: BeneficiaryIdentificationType[] = [];

    @observable
    maritalStatuses: MaritalStatus[] = [];

    @observable
    educationLevel: EducationLevel[] = [];

    @observable
    employmentStatus: EmploymentStatus[] = [];

    @observable
    employmentCharacteristics: EmploymentRelevantCharacteristics[] = [];

    @observable
    disabilityTypes: DisabilityType[] = [];

    @observable
    dependenceGrades: DependenceGrade[] = [];

    @observable
    therapyTypes: TherapyType[] = [];

    @observable
    documentCategories: DocumentCategory[] = [];

    @observable
    diagnosisTypes: DiagnosisType[] = [];

    @observable
    associateServiceToDependence: AssociateServiceToDependence[] = [];

    @observable
    countries: Country[] = [];

    constructor(
        @inject(GetAllGendersUseCase)
        private readonly getAllGendersUseCase: GetAllGendersUseCase,
        @inject(GetAllBeneficiaryIdentificationTypesUseCase)
        private readonly getAllBeneficiaryIdentificationTypesUseCase: GetAllBeneficiaryIdentificationTypesUseCase,
        @inject(GetMaritalStatusesUseCase)
        private readonly getMaritalStatusesUseCase: GetMaritalStatusesUseCase,
        @inject(GetAllEmploymentStatusUseCase)
        private readonly getAllEmploymentStatusUseCase: GetAllEmploymentStatusUseCase,
        @inject(GetAllEducationLevelsUseCase)
        private readonly getAllEducationLevelsUseCase: GetAllEducationLevelsUseCase,
        @inject(GetCountriesUseCase)
        private readonly getCountriesUseCase: GetCountriesUseCase,
        @inject(GetDisabilityTypesUseCase)
        private readonly getDisabilityTypesUseCase: GetDisabilityTypesUseCase,
        @inject(GetDependenceGradesUseCase)
        private readonly getDependenceGradesUseCase: GetDependenceGradesUseCase,
        @inject(GetTherapyTypesUseCase)
        private readonly getTherapyTypesUseCase: GetTherapyTypesUseCase,
        @inject(GetAssociateServiceToDependenceUseCase)
        private readonly getAssociateServiceToDependenceUseCase: GetAssociateServiceToDependenceUseCase,
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllDocumentCategoriesUseCase)
        private readonly getAllDocumentCategoriesUseCase: GetAllDocumentCategoriesUseCase,
        @inject(GetAllDiagnosisTypesUseCase)
        private readonly getAllDiagnosisTypesUseCase: GetAllDiagnosisTypesUseCase,
        @inject(GetAllEmploymentCharacteristicsUseCase)
        private readonly getAllEmploymentCharacteristicsUseCase: GetAllEmploymentCharacteristicsUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getCountries(),
            this.getDependenceGrades(),
            this.getDisabilityTypes(),
            this.getEducationLevels(),
            this.getEmploymentStatuses(),
            this.getEmploymentCharacteristics(),
            this.getGenders(),
            this.getIdentificationTypes(),
            this.getMaritalStatuses(),
            this.getTherapyTypes(),
            this.getAssociateServiceToDependence(),
            this.getAllDocumentCategories(),
            this.getDiagnosisTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getGenders(): Promise<void> {
        const genders = await this.getAllGendersUseCase.execute();

        runInAction(() => {
            this.genders = genders;
        });
    }

    async getIdentificationTypes(): Promise<void> {
        const identificationType =
            await this.getAllBeneficiaryIdentificationTypesUseCase.execute();

        runInAction(() => {
            this.identificationDocumentTypes = identificationType;
        });
    }

    async getMaritalStatuses(): Promise<void> {
        const maritalStatuses = await this.getMaritalStatusesUseCase.execute();

        runInAction(() => {
            this.maritalStatuses = maritalStatuses;
        });
    }

    async getEmploymentStatuses(): Promise<void> {
        const employmentStatuses =
            await this.getAllEmploymentStatusUseCase.execute();

        runInAction(() => {
            this.employmentStatus = employmentStatuses;
        });
    }

    async getEmploymentCharacteristics(): Promise<void> {
        const employmentCharac =
            await this.getAllEmploymentCharacteristicsUseCase.execute();

        runInAction(() => {
            this.employmentCharacteristics = employmentCharac;
        });
    }

    async getEducationLevels(): Promise<void> {
        const educationLevels =
            await this.getAllEducationLevelsUseCase.execute();

        runInAction(() => {
            this.educationLevel = educationLevels;
        });
    }

    async getCountries(): Promise<void> {
        const countries = await this.getCountriesUseCase.execute();

        runInAction(() => {
            this.countries = countries;
        });
    }

    async getDisabilityTypes(): Promise<void> {
        const disabilityTypes = await this.getDisabilityTypesUseCase.execute();

        runInAction(() => {
            this.disabilityTypes = disabilityTypes;
        });
    }

    async getDependenceGrades(): Promise<void> {
        const dependenceGrades =
            await this.getDependenceGradesUseCase.execute();

        runInAction(() => {
            this.dependenceGrades = dependenceGrades;
        });
    }

    async getTherapyTypes(): Promise<void> {
        const therapyTypes = await this.getTherapyTypesUseCase.execute();

        runInAction(() => {
            this.therapyTypes = therapyTypes;
        });
    }

    async getAssociateServiceToDependence(): Promise<void> {
        const associateServiceToDependence =
            await this.getAssociateServiceToDependenceUseCase.execute();

        runInAction(() => {
            this.associateServiceToDependence = associateServiceToDependence;
        });
    }

    async getAllDocumentCategories(): Promise<void> {
        const categories = await this.getAllDocumentCategoriesUseCase.execute();

        runInAction(() => {
            this.documentCategories = categories;
        });
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    async getDiagnosisTypes(): Promise<void> {
        const diagnosisTypes = await this.getAllDiagnosisTypesUseCase.execute();

        runInAction(() => {
            this.diagnosisTypes = diagnosisTypes;
        });
    }
}
