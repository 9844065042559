import { Cost } from "./cost.model";
import { CreateCost } from "./create-cost.model";

export class EditCost extends CreateCost {
    constructor(
        readonly id: Cost["id"],
        entityId: CreateCost["entityId"],
        invoiceNum: CreateCost["invoiceNum"],
        concept: CreateCost["concept"],
        issuerName: CreateCost["issuerName"],
        issuerIdentificationType: CreateCost["issuerIdentificationType"],
        identificationNumber: CreateCost["identificationNumber"],
        type: CreateCost["type"],
        invoiceDate: CreateCost["invoiceDate"],
        paymentDate: CreateCost["paymentDate"],
        paymentMethod: CreateCost["paymentMethod"],
        base: CreateCost["base"],
        iva: CreateCost["iva"],
        discount: CreateCost["discount"],
        total: CreateCost["total"],
        irpfType: CreateCost["irpfType"],
        othersType?: CreateCost["othersType"],
        otherPaymentMethod?: CreateCost["otherPaymentMethod"],
        document?: CreateCost["document"],
        additionalExpensesToUpdate?: CreateCost["additionalExpensesToUpdate"],
    ) {
        super(
            entityId,
            invoiceNum,
            concept,
            issuerName,
            issuerIdentificationType,
            identificationNumber,
            type,
            invoiceDate,
            paymentDate,
            paymentMethod,
            base,
            iva,
            discount,
            total,
            irpfType,
            othersType,
            otherPaymentMethod,
            document,
            additionalExpensesToUpdate,
        );
    }
}
