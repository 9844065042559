import { Nullable } from "@core/domain/types/nullable.type";
import { RegisteredRecordDto } from "@entity/data/dto/registered-record/registered-record.dto";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import { RegisteredRecord } from "@entity/domain/models/registered-record/registered-record.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class RegisteredRecordMapper {
    constructor(
        @inject(AdministrativeRecordScopeEnumMapper)
        private readonly administrativeRecordScopeEnumMapper: AdministrativeRecordScopeEnumMapper,
    ) {}
    map(registeredRecordDto: RegisteredRecordDto): Nullable<RegisteredRecord> {
        const errors = validateSync(registeredRecordDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const registeredAdministrativeRecordScope =
            this.administrativeRecordScopeEnumMapper.map(
                registeredRecordDto.registered_administrative_record_scope,
            );
        if (!registeredAdministrativeRecordScope) return null;

        return new RegisteredRecord(
            registeredRecordDto.id,
            registeredAdministrativeRecordScope,
            registeredRecordDto.registered_record_name,
            registeredRecordDto.record_identification_code,
            registeredRecordDto.entity,
        );
    }

    mapToDto(registeredRecord: RegisteredRecord): RegisteredRecordDto {
        const registered_administrative_record_scope =
            this.administrativeRecordScopeEnumMapper.mapToDto(
                registeredRecord.registeredAdministrativeRecordScope,
            );

        const registeredRecordDto: RegisteredRecordDto = {
            id: registeredRecord.id,
            registered_administrative_record_scope,
            registered_record_name: registeredRecord.registeredRecordName,
            record_identification_code:
                registeredRecord.recordIdentificationCode,
            entity: registeredRecord.entity,
        };

        return registeredRecordDto;
    }
}
