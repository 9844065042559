import { CreateEntity } from "@entity/domain/models/create-entity.model";
import { Entity } from "@entity/domain/models/entity.model";

export class EditEntity extends CreateEntity {
    public override identificationDocument: Entity["identification"];
    public override cnaeCode: Entity["cnaeCode"];
    public documents?: Entity["documents"];
    public address: Entity["address"];
    public override entityPurpose: Entity["entityPurpose"];
    public override webAddress: Entity["webAddress"];
    public override legalConstitutionDate: Entity["legalConstitutionDate"];
    public override publicInterest?: Entity["publicInterest"];
    public override equalityPlan?: Entity["equalityPlan"];
    public override equalityPlanExpirationDate?: Entity["equalityPlanExpirationDate"];

    constructor(
        public readonly id: Entity["id"],
        name: CreateEntity["name"],
        public override typologyId: CreateEntity["typologyId"],
        public override categoryId: CreateEntity["categoryId"],
        public override collectiveId: CreateEntity["collectiveId"],
        public override actionLines: CreateEntity["actionLines"],
        public override secondaryCollectives: CreateEntity["secondaryCollectives"],
        public override crosscuttingScopes?: CreateEntity["crosscuttingScopes"],
    ) {
        super(
            name,
            typologyId,
            categoryId,
            collectiveId,
            actionLines,
            secondaryCollectives,
            crosscuttingScopes,
        );
    }
}
