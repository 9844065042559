import { BeneficiaryIdentificationTypeEnumDto } from "@beneficiary/data/dto/beneficiary-identification-type.dto";
import { EditRelativesDto } from "@beneficiary/data/dto/relatives/edit-relatives.body";
import { EditRelative } from "@beneficiary/domain/models/relatives/edit-relative.model";
import { RelativeFormValuesValidated } from "@beneficiary/presentation/components/beneficiary-form/relatives/form/relative-form.view";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { inject, injectable } from "inversify";
import { BeneficiaryIdentificationTypeEnumMapper } from "../beneficiary-identification-type-enum.mapper";
import { RelationshipEnumMapper } from "../relationship-enum.mapper";

@injectable()
export class EditRelativeMapper {
    constructor(
        @inject(RelationshipEnumMapper)
        private readonly relationshipEnumMapper: RelationshipEnumMapper,
        @inject(BeneficiaryIdentificationTypeEnumMapper)
        private readonly beneficiaryIdentificationTypeEnumMapper: BeneficiaryIdentificationTypeEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
        @inject(EmploymentStatusEnumMapper)
        private readonly employmentStatusEnumMapper: EmploymentStatusEnumMapper,
    ) {}
    mapToDto(
        editRelative: EditRelative,
        beneficiaryId: number,
    ): EditRelativesDto {
        const relationship = this.relationshipEnumMapper.mapToDto(
            editRelative.relationship,
        );
        const identificationType =
            this.beneficiaryIdentificationTypeEnumMapper.mapToDto(
                editRelative.documentType,
            );
        const levelOfEducation = this.educationLevelEnumMapper.mapToDto(
            editRelative.educationLevel,
        );
        let employmentSituation = undefined;
        if (editRelative.employmentStatus) {
            employmentSituation = this.employmentStatusEnumMapper.mapToDto(
                editRelative.employmentStatus,
            );
        }
        const editRelativeDto: EditRelativesDto = {
            id: editRelative.id,
            beneficiary: beneficiaryId,
            parentage: relationship,
            birth_date: editRelative.birthdate.toISODate() ?? "",
            identification_type: identificationType,
            level_of_education: levelOfEducation,
            education_centre: editRelative.courseCenter,
            employment_situation: employmentSituation,
            net_salary: editRelative.netSalary?.toString(),
        };

        if (editRelative.tutorEmail && editRelative.tutorPhone) {
            editRelativeDto.tutelage_email = editRelative.tutorEmail;
            editRelativeDto.tutelage_phone = editRelative.tutorPhone;
        }

        if (
            identificationType !==
            BeneficiaryIdentificationTypeEnumDto.NoDocumentation
        ) {
            editRelative.documentId = editRelative.documentId ?? "";
        }

        return editRelativeDto;
    }

    mapFromFormValues(
        relativeForm: RelativeFormValuesValidated,
        relativeId: number,
    ): EditRelative {
        return new EditRelative(
            relativeId,
            relativeForm.relationship,
            relativeForm.birthdate,
            relativeForm.documentType,
            relativeForm.educationLevel,
            relativeForm.employmentStatus,
            relativeForm.documentId,
            relativeForm.courseCenter,
            relativeForm.tutorPhone,
            relativeForm.tutorEmail,
            relativeForm.netSalary ?? undefined,
        );
    }
}
