import type { Nullable } from "@core/domain/types/nullable.type";
import { DeleteDocumentUseCase } from "@core/domain/usecases/delete-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CostType } from "@entity/domain/models/cost/cost-type.model";
import {
    AdditionalExpense,
    AdditionalExpenseCreate,
} from "@entity/domain/models/cost/cost.model";
import { IRPFType } from "@entity/domain/models/cost/irpf-type.model";
import { IVAType } from "@entity/domain/models/cost/iva-type.model";
import { PaymentMethodType } from "@entity/domain/models/cost/payment-method-type.model";
import { IdentificationType } from "@entity/domain/models/identification-type.model";
import { ProjectSummary } from "@entity/domain/models/project-summary.model";
import { GetAllCostsTypesUseCase } from "@entity/domain/usecases/cost/get-all-costs-types.usecase";
import { GetAllIrpfTypesUseCase } from "@entity/domain/usecases/cost/get-all-irpf-types.usecase";
import { GetAllIvaTypesUseCase } from "@entity/domain/usecases/cost/get-all-iva-types.usecase";
import { GetAllPaymentMethodTypesUseCase } from "@entity/domain/usecases/cost/get-all-payment-method-types.usecase";
import { UpdateAdditionalExpensesUseCase } from "@entity/domain/usecases/cost/update-additional-expenses.usecase";
import { GetAllIdentificationTypesUseCase } from "@entity/domain/usecases/get-all-identification-types.usecase";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class CostFormViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    identificationTypes: IdentificationType[] = [];

    @observable
    paymentMethodTypes: PaymentMethodType[] = [];

    @observable
    ivaTypes: IVAType[] = [];

    @observable
    costTypes: CostType[] = [];

    @observable
    irpfTypes: IRPFType[] = [];

    @observable
    projects: ProjectSummary[] = [];

    @observable
    showLinkCostToProjectModal: boolean = false;

    @observable
    expenseSelected: Nullable<AdditionalExpense> = null;

    constructor(
        @inject(DeleteDocumentUseCase)
        private readonly deleteDocumentUseCase: DeleteDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllPaymentMethodTypesUseCase)
        private readonly getAllPaymentMethodTypesUseCase: GetAllPaymentMethodTypesUseCase,
        @inject(GetAllIdentificationTypesUseCase)
        private readonly getAllIdentificationTypesUseCase: GetAllIdentificationTypesUseCase,
        @inject(GetAllProjectsUseCase)
        private readonly getAllProjectsUseCase: GetAllProjectsUseCase,
        @inject(GetAllIvaTypesUseCase)
        private readonly getAllIvaTypesUseCase: GetAllIvaTypesUseCase,
        @inject(GetAllCostsTypesUseCase)
        private readonly getAllCostsTypesUseCase: GetAllCostsTypesUseCase,
        @inject(GetAllIrpfTypesUseCase)
        private readonly getAllIrpfTypesUseCase: GetAllIrpfTypesUseCase,
        @inject(UpdateAdditionalExpensesUseCase)
        private readonly updateAdditionalExpensesUseCase: UpdateAdditionalExpensesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllPaymentMethodTypes(),
            this.getIdentificationTypes(),
            this.getAllProjects(),
            this.getAllIvaTypes(),
            this.getAllCostsTypes(),
            this.getAllIrpfTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllPaymentMethodTypes(): Promise<void> {
        const paymentMethodTypes =
            await this.getAllPaymentMethodTypesUseCase.execute();

        runInAction(() => {
            this.paymentMethodTypes = paymentMethodTypes;
        });
    }

    async getAllIvaTypes(): Promise<void> {
        const ivaTypes = await this.getAllIvaTypesUseCase.execute();

        runInAction(() => {
            this.ivaTypes = ivaTypes;
        });
    }

    async getAllCostsTypes(): Promise<void> {
        const costsTypes = await this.getAllCostsTypesUseCase.execute();

        runInAction(() => {
            this.costTypes = costsTypes;
        });
    }

    async getAllIrpfTypes(): Promise<void> {
        const irpfTypes = await this.getAllIrpfTypesUseCase.execute();

        runInAction(() => {
            this.irpfTypes = irpfTypes;
        });
    }

    async getIdentificationTypes(): Promise<void> {
        const identificationTypes =
            await this.getAllIdentificationTypesUseCase.execute();

        runInAction(() => {
            this.identificationTypes = identificationTypes;
        });
    }

    async getAllProjects(): Promise<void> {
        const projects = await this.getAllProjectsUseCase.execute();

        runInAction(() => {
            this.projects = projects;
        });
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        if (documentUrl) {
            await this.downloadDocumentUseCase.execute(documentUrl);
        }

        LoadLayoutStore.finish();
    }

    async deleteDocument(documentId: number): Promise<boolean> {
        LoadLayoutStore.start();

        const deleted = await this.deleteDocumentUseCase.execute(documentId);

        if (deleted) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return deleted;
    }

    @action
    closeLinkCostToProjectModal(): void {
        this.showLinkCostToProjectModal = false;
        this.expenseSelected = null;
    }

    @action
    openLinkCostToProjectModal(): void {
        this.showLinkCostToProjectModal = true;
    }

    @action
    setExpenseSelected(expense: AdditionalExpense): void {
        this.expenseSelected = expense;
    }

    async updateAdditionalExpense(
        expense: AdditionalExpenseCreate,
    ): Promise<void> {
        LoadLayoutStore.start();

        const updated =
            await this.updateAdditionalExpensesUseCase.execute(expense);

        if (updated) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
