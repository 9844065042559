import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DepositTypeEnumDto {
    PRIVATE_GRANT = "private_grant",
    FUNDING_AGENCY = "funding_agency",
    DONATION = "donation",
    FUNDRAISING_CAMPAIGN = "fundraising_campaign",
    PUBLIC_ADMINISTRATION_CONTRACT = "public_dministration_contract",
    PRIVATE_ENTITY_CONTRACT = "private_entity_contract",
    MERCH = "merch",
    MEMBERSHIP_FEES = "membership_fees",
    OTHER = "other",
}

export class DepositTypesDto {
    @IsEnum(DepositTypeEnumDto)
    @IsNotEmpty()
    key!: DepositTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
