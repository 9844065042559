import {
    BeneficiaryDto,
    BeneficiaryIdentificationDto,
} from "@beneficiary/data/dto/beneficiary.dto";
import { ExpenseMapper } from "@beneficiary/data/mappers/expense.mapper";
import { IncomeMapper } from "@beneficiary/data/mappers/income.mapper";
import {
    Beneficiary,
    BeneficiaryIdentification,
} from "@beneficiary/domain/models/beneficiary.model";
import { DisabilityTypeEnum } from "@beneficiary/domain/models/disability-type.enum";
import { Income } from "@beneficiary/domain/models/economic-data/income/income.model";
import { EmploymentSituationRelevantCharacteristicsEnum } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Relative } from "@beneficiary/domain/models/relatives/relative.model";
import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { EducationLevelEnumMapper } from "@core/data/mappers/education-level-enum.mapper";
import { EmploymentStatusEnumMapper } from "@core/data/mappers/employment-status-enum.mapper";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable, isNullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { AddressMapper } from "./address/address.mapper";
import { AssociateServiceToDependenceEnumMapper } from "./associate-service-to-dependence-enum.mapper";
import { BeneficiaryIdentificationTypeEnumMapper } from "./beneficiary-identification-type-enum.mapper";
import { CountryEnumMapper } from "./country-enum.mapper";
import { DependenceGradesEnumMapper } from "./dependence-grades-enum.mapper";
import { EmploymentCharacteristicsEnumMapper } from "./employment-characteristics-enum.mapper";
import { GenderEnumMapper } from "./gender-enum.mapper";
import { MaritalStatusEnumMapper } from "./marital-status-enum.mapper";
import { TherapyTypeEnumMapper } from "./therapy-type-enum.mapper";
import { DiagnosisTypeEnumMapper } from "./diagnosis-type-enum.mapper";
import { DisabilityTypesEnumMapper } from "./disability-type-enum.mapper";

@injectable()
export class BeneficiaryMapper {
    // eslint-disable-next-line max-params
    constructor(
        @inject(AddressMapper)
        private readonly addressMapper: AddressMapper,
        @inject(CountryEnumMapper)
        private readonly countryEnumMapper: CountryEnumMapper,
        @inject(EducationLevelEnumMapper)
        private readonly educationLevelEnumMapper: EducationLevelEnumMapper,
        @inject(EmploymentStatusEnumMapper)
        private readonly employmentStatusEnumMapper: EmploymentStatusEnumMapper,
        @inject(ExpenseMapper)
        private readonly expenseMapper: ExpenseMapper,
        @inject(GenderEnumMapper)
        private readonly genderEnumMapper: GenderEnumMapper,
        @inject(BeneficiaryIdentificationTypeEnumMapper)
        private readonly beneficiaryIdentificationTypetEnumMapper: BeneficiaryIdentificationTypeEnumMapper,
        @inject(IncomeMapper)
        private readonly incomeMapper: IncomeMapper,
        @inject(MaritalStatusEnumMapper)
        private readonly maritalStatusEnumMapper: MaritalStatusEnumMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(DisabilityTypesEnumMapper)
        private readonly disabilityTypesEnumMapper: DisabilityTypesEnumMapper,
        @inject(DependenceGradesEnumMapper)
        private readonly dependenceGradesEnumMapper: DependenceGradesEnumMapper,
        @inject(TherapyTypeEnumMapper)
        private readonly therapyTypeEnumMapper: TherapyTypeEnumMapper,
        @inject(AssociateServiceToDependenceEnumMapper)
        private readonly associateServiceToDependenceEnumMapper: AssociateServiceToDependenceEnumMapper,
        @inject(DiagnosisTypeEnumMapper)
        private readonly diagnosisTypeEnumMapper: DiagnosisTypeEnumMapper,
        @inject(EmploymentCharacteristicsEnumMapper)
        private readonly employmentCharacteristicsEnumMapper: EmploymentCharacteristicsEnumMapper,
    ) {}

    map(beneficiaryDto: BeneficiaryDto): Nullable<Beneficiary> {
        const errors = validateSync(beneficiaryDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const gender = this.genderEnumMapper.map(beneficiaryDto.gender);

        if (!gender) return null;

        const nationality = this.countryEnumMapper.map(
            beneficiaryDto.nationality,
        );

        if (!nationality) return null;

        const country_of_origin = this.countryEnumMapper.map(
            beneficiaryDto.country_of_origin,
        );

        if (!country_of_origin) return null;

        const marital_status = this.maritalStatusEnumMapper.map(
            beneficiaryDto.marital_status,
        );

        if (!marital_status) return null;

        const level_of_education = this.educationLevelEnumMapper.map(
            beneficiaryDto.level_of_education,
        );

        if (!level_of_education) return null;

        const employment_situation = this.employmentStatusEnumMapper.map(
            beneficiaryDto.employment_situation,
        );

        if (!employment_situation) return null;

        const relatives: Relative[] = [];

        let income: Nullish<Income> = undefined;
        if (beneficiaryDto.income) {
            income = this.incomeMapper.map(beneficiaryDto.income);

            if (isNullable(income)) return null;
        }

        const expenses = (beneficiaryDto.expenses ?? []).mapNotNull((expense) =>
            this.expenseMapper.map(expense),
        );

        const documentsData: IncDocument[] =
            beneficiaryDto.documents_data?.mapNotNull((doc) =>
                this.documentMapper.map(doc),
            ) ?? [];

        let disabilityType: NonNullable<DisabilityTypeEnum>[] = [];
        if (beneficiaryDto.disability_types) {
            disabilityType = beneficiaryDto.disability_types.mapNotNull(
                (disability_type) =>
                    this.disabilityTypesEnumMapper.map(disability_type),
            );
        }

        const dependenceGrade = beneficiaryDto.dependence_grade
            ? this.dependenceGradesEnumMapper.map(
                  beneficiaryDto.dependence_grade,
              )
            : null;

        const therapyType = beneficiaryDto.therapy_type
            ? this.therapyTypeEnumMapper.map(beneficiaryDto.therapy_type)
            : null;

        const associateServiceToDependence =
            beneficiaryDto.associate_service_to_dependence
                ? this.associateServiceToDependenceEnumMapper.map(
                      beneficiaryDto.associate_service_to_dependence,
                  )
                : null;

        let diagnosisType = null;
        if (beneficiaryDto.diagnosis_type !== "") {
            diagnosisType = this.diagnosisTypeEnumMapper.map(
                beneficiaryDto.diagnosis_type,
            );
        }

        let employmentCharacs: NonNullable<EmploymentSituationRelevantCharacteristicsEnum>[] =
            [];
        if (beneficiaryDto.employment_situation_relevant_characteristics) {
            employmentCharacs =
                beneficiaryDto.employment_situation_relevant_characteristics.mapNotNull(
                    (employment_situation_relevant_characteristics) =>
                        this.employmentCharacteristicsEnumMapper.map(
                            employment_situation_relevant_characteristics,
                        ),
                );
        }

        const newBeneficiary = new Beneficiary(
            beneficiaryDto.id,
            beneficiaryDto.name,
            beneficiaryDto.surname,
            [], // Identifications. Left it empty in order to assign it latter
            gender,
            beneficiaryDto.phone ?? undefined,
            beneficiaryDto.phone_owner ?? undefined,
            beneficiaryDto.email ?? undefined,
            beneficiaryDto.email_owner ?? undefined,
            nationality,
            country_of_origin,
            beneficiaryDto.birth_date
                ? DateTime.fromISO(beneficiaryDto.birth_date)
                : undefined,
            marital_status,
            level_of_education,

            beneficiaryDto.complementary_training ?? undefined,
            employment_situation,
            employmentCharacs,
            beneficiaryDto.other_employment_situation_relevant_characteristics ??
                null,
            relatives,
            beneficiaryDto.intervention_date
                ? DateTime.fromISO(beneficiaryDto.intervention_date)
                : undefined,
            beneficiaryDto.disability,
            disabilityType,
            beneficiaryDto.health_observations ?? "",
            beneficiaryDto.has_dependence,
            dependenceGrade,
            associateServiceToDependence,
            beneficiaryDto.dependence_diagnosis,
            beneficiaryDto.dependence_observations,
            beneficiaryDto.in_therapy,
            therapyType,
            beneficiaryDto.is_diagnosed,
            diagnosisType,
            beneficiaryDto.diagnosis_date
                ? DateTime.fromISO(beneficiaryDto.diagnosis_date)
                : null,
            beneficiaryDto.under_protection,
            beneficiaryDto.has_legal_guardian,
            income,
            expenses,
            undefined,
            documentsData,
        );

        if (beneficiaryDto.usual_address && beneficiaryDto.usual_address_data)
            newBeneficiary.usualAddress = this.addressMapper.map(
                beneficiaryDto.usual_address_data,
            );
        if (beneficiaryDto.census_address && beneficiaryDto.census_address_data)
            newBeneficiary.censusAddress = this.addressMapper.map(
                beneficiaryDto.census_address_data,
            );

        return newBeneficiary;
    }

    mapIdentification(
        beneficiaryIdentificationDto: BeneficiaryIdentificationDto,
    ): Nullable<BeneficiaryIdentification> {
        const errors = validateSync(beneficiaryIdentificationDto);

        if (errors.length) {
            console.error(errors);
            return null;
        }

        const identificationType =
            this.beneficiaryIdentificationTypetEnumMapper.map(
                beneficiaryIdentificationDto.identification_type,
            );

        if (!identificationType) return null;

        return new BeneficiaryIdentification(
            beneficiaryIdentificationDto.id,
            identificationType,
            beneficiaryIdentificationDto.identification ?? undefined,
            beneficiaryIdentificationDto.identification_expiration
                ? DateTime.fromISO(
                      beneficiaryIdentificationDto.identification_expiration,
                  )
                : undefined,
            beneficiaryIdentificationDto.beneficiary,
        );
    }
}
