import { IdentificationType } from "@core/domain/models/identification-document-type.model";
import { Pagination } from "@core/domain/models/pagination";
import type { Nullable } from "@core/domain/types/nullable.type";
import { GetAllIdentificationDocumentTypesUseCase } from "@core/domain/usecases/get-all-identification-document-types.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { RoomMapper } from "@entity/data/mappers/locals/room.mapper";
import { CostPeriodicityType } from "@entity/domain/models/locals/cost-periodicity.model";
import { Rooms } from "@entity/domain/models/locals/room.model";
import type { RoomsSearchFilters } from "@entity/domain/models/locals/rooms-search-filters";
import {
    StatusType,
    StatusTypeEnum,
} from "@entity/domain/models/locals/status.model";
import { CreateRoomUseCase } from "@entity/domain/usecases/locals/create-room.usecase";
import { DeleteRoomUseCase } from "@entity/domain/usecases/locals/delete-room.usecase";
import { GetAllCostPeriodicitiesUseCase } from "@entity/domain/usecases/locals/get-all-cost-periodicities.usecase";
import { GetAllStatusTypesUseCase } from "@entity/domain/usecases/locals/get-all-status-types.usecase";
import { SearchRoomsByUseCase } from "@entity/domain/usecases/locals/search-rooms.usecase";
import { RoomFormValuesValidated } from "@entity/presentation/component/entity-form/locals/form/room.form";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

const PAGE_SIZE = 5;

@injectable()
export class LocalViewModel extends BaseViewModel {
    constructor(
        @inject(GetAllCostPeriodicitiesUseCase)
        private readonly getAllCostPeriodicitiesUseCase: GetAllCostPeriodicitiesUseCase,
        @inject(GetAllStatusTypesUseCase)
        private readonly getAllStatusUseCase: GetAllStatusTypesUseCase,
        @inject(SearchRoomsByUseCase)
        private readonly searchRoomsByUseCase: SearchRoomsByUseCase,
        @inject(CreateRoomUseCase)
        private readonly createRoomUseCase: CreateRoomUseCase,
        @inject(DeleteRoomUseCase)
        private readonly deleteRoomUseCase: DeleteRoomUseCase,
        @inject(RoomMapper)
        private readonly roomMapper: RoomMapper,
        @inject(GetAllIdentificationDocumentTypesUseCase)
        private readonly getAllIdentificationDocumentTypesUseCase: GetAllIdentificationDocumentTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    initialLoading = true;

    @observable
    costPeriodicities: CostPeriodicityType[] = [];

    @observable
    statusTypes: StatusType[] = [];

    @observable
    identificationTypes: IdentificationType[] = [];

    @observable
    statusSelectedId: Nullable<StatusTypeEnum> = null;

    @observable
    statusSelectedLabel: string = "";

    @observable
    localCodeSelected: string = "";

    @observable
    cadrastralReferenceSelected: string = "";

    @observable
    rooms: Rooms = new Rooms([], 0);

    @observable
    pagination: Pagination = new Pagination(1, PAGE_SIZE);

    @observable
    isRoomModalOpen = false;

    initialFiltersValue: RoomsSearchFilters = {
        localId: null,
    };

    @observable
    filters: RoomsSearchFilters = this.initialFiltersValue;

    override async didMount(): Promise<void> {
        await this.initViewData();
    }

    private async initViewData(): Promise<void> {
        await Promise.all([
            this.getAllCostPeriodicities(),
            this.getAllStatusTypes(),
            this.getAllIdentificationDocumentTypes(),
            this.searchRoomsBy(this.filters, this.pagination),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    async getAllCostPeriodicities(): Promise<void> {
        const costPeriodicities =
            await this.getAllCostPeriodicitiesUseCase.execute();

        runInAction(() => {
            this.costPeriodicities = costPeriodicities;
        });
    }

    async getAllStatusTypes(): Promise<void> {
        const statusTypes = await this.getAllStatusUseCase.execute();

        runInAction(() => {
            this.statusTypes = statusTypes;
        });
    }

    async searchRoomsBy(
        filters: RoomsSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const rooms = await this.searchRoomsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this.rooms = rooms;
        });
    }

    async getAllIdentificationDocumentTypes(): Promise<void> {
        const identificationDocumentTypes =
            await this.getAllIdentificationDocumentTypesUseCase.execute();

        runInAction(() => {
            this.identificationTypes = identificationDocumentTypes;
        });
    }

    @action
    openAddRoomModal(): void {
        this.isRoomModalOpen = true;
    }

    @action
    closeAddRoomModal(): void {
        this.isRoomModalOpen = false;
    }

    @action
    setStatusSelected = (type: StatusTypeEnum): void => {
        this.statusSelectedId = type;
    };

    @action
    setLocalCodeSelected = (value: string): void => {
        this.localCodeSelected = value;
    };

    @action
    setCadrastralReferenceSelected = (value: string): void => {
        this.cadrastralReferenceSelected = value;
    };

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchRoomsBy(this.filters, this.pagination);
    }

    @action
    setFilters(filters: RoomsSearchFilters): void {
        this.filters = filters;
    }

    async deleteRoom(roomId: number): Promise<void> {
        LoadLayoutStore.start();

        const success = await this.deleteRoomUseCase.execute(roomId);
        if (success) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async createRoom(room: RoomFormValuesValidated): Promise<void> {
        LoadLayoutStore.start();

        const createRoomResult = await this.createRoomUseCase.execute(
            this.roomMapper.mapFromFormToCreate(room),
        );

        if (createRoomResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async reloadRooms(): Promise<void> {
        LoadLayoutStore.start();
        await this.searchRoomsBy(this.filters, this.pagination);
        LoadLayoutStore.finish();
    }
}
