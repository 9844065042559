export enum StatusTypeEnum {
    LOAN = "loan",
    PROPERTY = "property",
    RENT = "rent",
}

export class StatusType {
    constructor(
        public readonly type: StatusTypeEnum,
        public readonly label: string,
    ) {}
}
