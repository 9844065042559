import { Nullable } from "@core/domain/types/nullable.type";
import { CostTypeEnumDto } from "@entity/data/dto/cost/cost-type.dto";
import { CostTypeEnum } from "@entity/domain/models/cost/cost-type.model";
import { injectable } from "inversify";

@injectable()
export class CostTypeEnumMapper {
    map(costTypeEnumDto: CostTypeEnumDto): Nullable<CostTypeEnum> {
        switch (costTypeEnumDto) {
            case CostTypeEnumDto.activity:
                return CostTypeEnum.activity;
            case CostTypeEnumDto.admin:
                return CostTypeEnum.admin;
            case CostTypeEnumDto.audit:
                return CostTypeEnum.audit;
            case CostTypeEnumDto.benefit_INVOICE:
                return CostTypeEnum.benefit_INVOICE;
            case CostTypeEnumDto.freelance:
                return CostTypeEnum.freelance;
            case CostTypeEnumDto.fungible:
                return CostTypeEnum.fungible;
            case CostTypeEnumDto.furniture:
                return CostTypeEnum.furniture;
            case CostTypeEnumDto.info_INVOICE:
                return CostTypeEnum.info_INVOICE;
            case CostTypeEnumDto.isos:
                return CostTypeEnum.isos;
            case CostTypeEnumDto.license:
                return CostTypeEnum.license;
            case CostTypeEnumDto.local:
                return CostTypeEnum.local;
            case CostTypeEnumDto.maintenance:
                return CostTypeEnum.maintenance;
            case CostTypeEnumDto.supplier:
                return CostTypeEnum.supplier;
            case CostTypeEnumDto.supply:
                return CostTypeEnum.supply;
            case CostTypeEnumDto.other:
                return CostTypeEnum.other;
            default:
                return null;
        }
    }

    mapToDto(costTypeEnum: CostTypeEnum): CostTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (costTypeEnum) {
            case CostTypeEnum.activity:
                return CostTypeEnumDto.activity;
            case CostTypeEnum.admin:
                return CostTypeEnumDto.admin;
            case CostTypeEnum.audit:
                return CostTypeEnumDto.audit;
            case CostTypeEnum.benefit_INVOICE:
                return CostTypeEnumDto.benefit_INVOICE;
            case CostTypeEnum.freelance:
                return CostTypeEnumDto.freelance;
            case CostTypeEnum.fungible:
                return CostTypeEnumDto.fungible;
            case CostTypeEnum.furniture:
                return CostTypeEnumDto.furniture;
            case CostTypeEnum.info_INVOICE:
                return CostTypeEnumDto.info_INVOICE;
            case CostTypeEnum.isos:
                return CostTypeEnumDto.isos;
            case CostTypeEnum.license:
                return CostTypeEnumDto.license;
            case CostTypeEnum.local:
                return CostTypeEnumDto.local;
            case CostTypeEnum.maintenance:
                return CostTypeEnumDto.maintenance;
            case CostTypeEnum.supplier:
                return CostTypeEnumDto.supplier;
            case CostTypeEnum.supply:
                return CostTypeEnumDto.supply;
            case CostTypeEnum.other:
                return CostTypeEnumDto.other;
        }
    }
}
