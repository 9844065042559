import { Nullable } from "@core/domain/types/nullable.type";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import {
    DepositTypeEnum,
    DepositTypes,
} from "../../domain/models/deposit-type.model";
import { DepositTypesDto } from "../dto/deposit-types.dto";
import { DepositTypeEnumMapper } from "./deposit-type-enum.mapper";

@injectable()
export class DepositTypesMapper {
    constructor(
        @inject(DepositTypeEnumMapper)
        private readonly depositTypeEnumMapper: DepositTypeEnumMapper,
    ) {}

    map(transportTypeDto: DepositTypesDto): Nullable<DepositTypes> {
        const errors = validateSync(DepositTypesDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const id: Nullable<DepositTypeEnum> = this.depositTypeEnumMapper.map(
            transportTypeDto.key,
        );

        if (!id) return null;

        return new DepositTypes(id, transportTypeDto.value);
    }
}
