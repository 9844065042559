import { CountryEnum } from "@core/domain/models/country.model";
import { DiagnosisTypeEnum } from "@core/domain/models/diagnosis-type.model";
import { EducationLevelEnum } from "@core/domain/models/education-level.model";
import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Proceeding } from "@proceeding/domain/models/proceeding.model";
import { DateTime } from "luxon";
import { Address } from "./address.model";
import { AssociateServiceToDependenceEnum } from "./associate-service-to-dependence.enum";
import { Expense } from "./economic-data/expense/expense.model";
import { Income } from "./economic-data/income/income.model";
import { EmploymentSituationRelevantCharacteristicsEnum } from "./employment-situation-relevant-characteristics.enum";
import { GenderEnum } from "./gender.model";
import { BeneficiaryIdentificationTypeEnum } from "./identification-document-type.model";
import { MaritalStatusEnum } from "./marital-status.model";
import { Relative } from "./relatives/relative.model";
import { DependenceGradeEnum } from "@beneficiary/domain/models/dependence-grade.enum";
import { DisabilityTypeEnum } from "@beneficiary/domain/models/disability-type.enum";
import { TherapyTypeEnum } from "@beneficiary/domain/models/therapy-type.enum";

export class BeneficiaryIdentification {
    constructor(
        public readonly id: number,
        public identificationType: BeneficiaryIdentificationTypeEnum,
        public identification: Undefinable<string>,
        public identificationExpiration: Undefinable<DateTime>,
        public beneficiary: number,
    ) {}
}

export class Beneficiary {
    public usualAddress?: Nullable<Address>;
    public censusAddress?: Nullable<Address>;

    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public name: string,
        public surname: string,
        public identifications: BeneficiaryIdentification[],
        public gender: GenderEnum,
        public phone: Undefinable<string>,
        public phoneOwner: Undefinable<string>,
        public email: Undefinable<string>,
        public emailOwner: Undefinable<string>,
        public nationality: CountryEnum,
        public countryOfOrigin: CountryEnum,
        public birthDate: Undefinable<DateTime>,
        public maritalStatus: MaritalStatusEnum,
        public levelOfEducation: EducationLevelEnum,
        public complementaryTraining: Undefinable<string>,
        public employmentSituation: EmploymentStatusEnum,
        public employmentSituationCharacteristics: Nullable<
            EmploymentSituationRelevantCharacteristicsEnum[]
        >,
        public otherEmploymentSituationCharacteristics: Nullable<string>,
        public relatives: Nullable<Relative[]>,
        public interventionDate: Undefinable<DateTime>,
        public disability: boolean,
        public disabilityType: Nullable<DisabilityTypeEnum[]>,
        public healthObservations: string,
        public dependence: boolean,
        public dependenceGrade: Nullable<DependenceGradeEnum>,
        public associateServiceToDependence: Nullable<AssociateServiceToDependenceEnum>,
        public dependenceDiagnosis: Undefinable<string>,
        public dependenceObservations: Nullable<string>,
        public inTherapy: boolean,
        public therapyType: Nullable<TherapyTypeEnum>,
        public isDiagnosed: boolean,
        public diagnosisType: Nullable<DiagnosisTypeEnum>,
        public diagnosisDate: Nullable<DateTime>,
        public underProtection: boolean,
        public hasLegalGuardian: boolean,
        public income?: Income,
        public expenses: Expense[] = [],
        public historyEntities?: Proceeding[],
        public documentsData: IncDocument[] = [],
    ) {}

    get fullName(): string {
        return `${this.name} ${this.surname}`;
    }
}
