import { Nullable } from "@core/domain/types/nullable.type";
import { CostPeriodicityTypeEnumDto } from "@entity/data/dto/locals/cost-periodicity.dto";
import { CostPeriodicityTypeEnum } from "@entity/domain/models/locals/cost-periodicity.model";
import { injectable } from "inversify";

@injectable()
export class CostPeriodicityTypeEnumMapper {
    map(
        entityTypeEnumDto: CostPeriodicityTypeEnumDto,
    ): Nullable<CostPeriodicityTypeEnum> {
        switch (entityTypeEnumDto) {
            case CostPeriodicityTypeEnumDto.ANNUAL:
                return CostPeriodicityTypeEnum.ANNUAL;
            case CostPeriodicityTypeEnumDto.MONTHLY:
                return CostPeriodicityTypeEnum.MONTHLY;
            case CostPeriodicityTypeEnumDto.SESSION:
                return CostPeriodicityTypeEnum.SESSION;
            case CostPeriodicityTypeEnumDto.JOURNEY:
                return CostPeriodicityTypeEnum.JOURNEY;
            default:
                return null;
        }
    }

    mapToDto(
        entityTypeEnum: CostPeriodicityTypeEnum,
    ): CostPeriodicityTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (entityTypeEnum) {
            case CostPeriodicityTypeEnum.ANNUAL:
                return CostPeriodicityTypeEnumDto.ANNUAL;
            case CostPeriodicityTypeEnum.MONTHLY:
                return CostPeriodicityTypeEnumDto.MONTHLY;
            case CostPeriodicityTypeEnum.SESSION:
                return CostPeriodicityTypeEnumDto.SESSION;
            case CostPeriodicityTypeEnum.JOURNEY:
                return CostPeriodicityTypeEnumDto.JOURNEY;
        }
    }
}
