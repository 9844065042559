import { Nullable } from "@core/domain/types/nullable.type";
import { TerminationReasonEnumDto } from "@entity/data/dto/employee/termination-reason.dto";
import { TerminationReasonEnum } from "@entity/domain/models/employee/termination-reason.model";
import { injectable } from "inversify";

@injectable()
export class TerminationReasonEnumMapper {
    map(
        terminationReasonEnumDto: TerminationReasonEnumDto,
    ): Nullable<TerminationReasonEnum> {
        switch (terminationReasonEnumDto) {
            case TerminationReasonEnumDto.resignation:
                return TerminationReasonEnum.RESIGNATION;
            case TerminationReasonEnumDto.disciplinary_dismissal:
                return TerminationReasonEnum.DISCIPLINARY_DISMISSAL;
            case TerminationReasonEnumDto.objective_dismissal:
                return TerminationReasonEnum.OBJECTIVE_DISMISSAL;
            case TerminationReasonEnumDto.collective_dismissal:
                return TerminationReasonEnum.COLLECTIVE_DISMISSAL;
            case TerminationReasonEnumDto.improper_dismissal:
                return TerminationReasonEnum.IMPROPER_DISMISSAL;
            case TerminationReasonEnumDto.temporary_leave:
                return TerminationReasonEnum.TEMPORARY_LEAVE;
            case TerminationReasonEnumDto.death:
                return TerminationReasonEnum.DEATH;
            default:
                return null;
        }
    }

    mapToDto(
        terminationReasonEnum: TerminationReasonEnum,
    ): TerminationReasonEnumDto {
        // eslint-disable-next-line default-case
        switch (terminationReasonEnum) {
            case TerminationReasonEnum.RESIGNATION:
                return TerminationReasonEnumDto.resignation;
            case TerminationReasonEnum.DISCIPLINARY_DISMISSAL:
                return TerminationReasonEnumDto.disciplinary_dismissal;
            case TerminationReasonEnum.OBJECTIVE_DISMISSAL:
                return TerminationReasonEnumDto.objective_dismissal;
            case TerminationReasonEnum.COLLECTIVE_DISMISSAL:
                return TerminationReasonEnumDto.collective_dismissal;
            case TerminationReasonEnum.IMPROPER_DISMISSAL:
                return TerminationReasonEnumDto.improper_dismissal;
            case TerminationReasonEnum.TEMPORARY_LEAVE:
                return TerminationReasonEnumDto.temporary_leave;
            case TerminationReasonEnum.DEATH:
                return TerminationReasonEnumDto.death;
        }
    }
}
