import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { EditCostMapper } from "@entity/data/mappers/cost/edit-cost.mapper";
import {
    AdditionalExpenseCreate,
    Cost,
} from "@entity/domain/models/cost/cost.model";
import { IRPFTypeEnum } from "@entity/domain/models/cost/irpf-type.model";
import { GetCostByIdUseCase } from "@entity/domain/usecases/cost/get-cost-by-id.usecase";
import { UpdateCostUseCase } from "@entity/domain/usecases/cost/update-cost.usecase";
import { CostFormValuesValidated } from "@entity/presentation/component/cost-form/cost-form.component";
import { CreateExpenseMapper } from "@project/data/mappers/expenses/create-expense.mapper";
import { EditExpenseMapper } from "@project/data/mappers/expenses/edit-expense.mapper";
import type { ExpenseSearchFilters } from "@project/domain/models/expenses/expense-search-filters";
import { ExpenseType } from "@project/domain/models/expenses/expense-type.model";
import { ExpenseAllTypes } from "@project/domain/models/expenses/expense.model";
import { Expenses } from "@project/domain/models/expenses/expenses.model";
import { CreateExpenseUseCase } from "@project/domain/usecases/expenses/create-expense.usecase";
import { DeleteExpenseUseCase } from "@project/domain/usecases/expenses/delete-expense.usecase";
import { EditExpenseUseCase } from "@project/domain/usecases/expenses/edit-expense.usecase";
import { GetAllExpensesUseCase } from "@project/domain/usecases/expenses/get-all-expenses.usecase";
import { GetAllProjectExpenseTypesUseCase } from "@project/domain/usecases/expenses/get-all-project-expense-types.usecase";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import {
    action,
    computed,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { EntityCostFormValuesValidated } from "./entity-cost-form/entity-cost-form.component";
import { ExpensesFormValuesValidated } from "./expenses-form/expense-modal-body.component";

export interface ExpenseListTable {
    id: number;
    typeLabel: string;
    subTypeLabel: string;
    totalCost: number;
}

interface ExpensesListTable {
    expenses: ExpenseListTable[];
    count: number;
}

export interface DocumentTable {
    id: number;
    name: string;
    uploadDate: DateTime;
}

@injectable()
export class ExpensesTabViewModel extends BaseViewModel {
    projectId: Undefinable<number>;

    @observable
    expensesModalIsOpen: boolean = false;

    @observable
    linkEntityCostModalIsOpen: boolean = false;

    @observable
    initialLoading: boolean = true;

    @observable
    types: ExpenseType[] = [];

    @observable
    private _expenses: Expenses = new Expenses([], 0);

    @observable
    expenses: ExpenseAllTypes[] = [];

    @observable
    expenseForm: Undefinable<ExpenseAllTypes>;

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: Omit<ExpenseSearchFilters, "projectId"> = {
        text: "",
        type: null,
    };

    @observable
    filters: ExpenseSearchFilters = this.initialFiltersValue;

    @computed
    get expensesTable(): ExpensesListTable {
        return {
            count: this._expenses.count,
            expenses: this._expenses.expenses.map((expense) => ({
                id: expense.id,
                typeLabel: expense.typeLabel,
                subTypeLabel:
                    "subTypeLabel" in expense ? expense.subTypeLabel : "",
                totalCost: expense.amountImputation ?? 0,
            })),
        };
    }

    constructor(
        @inject(CreateExpenseMapper)
        private readonly createExpenseMapper: CreateExpenseMapper,
        @inject(CreateExpenseUseCase)
        private readonly createExpenseUseCase: CreateExpenseUseCase,
        @inject(EditExpenseMapper)
        private readonly editExpenseMapper: EditExpenseMapper,
        @inject(EditExpenseUseCase)
        private readonly editExpenseUseCase: EditExpenseUseCase,
        @inject(GetAllExpensesUseCase)
        private readonly getAllExpensesUseCase: GetAllExpensesUseCase,
        @inject(GetAllProjectExpenseTypesUseCase)
        private readonly getAllProjectExpenseTypesUseCase: GetAllProjectExpenseTypesUseCase,
        @inject(DeleteExpenseUseCase)
        private readonly deleteExpenseUseCase: DeleteExpenseUseCase,
        @inject(UpdateCostUseCase)
        private readonly updateCostUseCase: UpdateCostUseCase,
        @inject(EditCostMapper)
        private readonly editCostMapper: EditCostMapper,
        @inject(GetCostByIdUseCase)
        private readonly getCostByIdUseCase: GetCostByIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult([
            this.getAllExpenses(this.pagination, this.filters),
            this.getAllExpenseTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    openExpensesModal(): void {
        this.expensesModalIsOpen = true;
    }

    @action
    closeExpensesModal(): void {
        this.expenseForm = undefined;
        this.expensesModalIsOpen = false;
    }

    @action
    openLinkEntityCost(): void {
        this.linkEntityCostModalIsOpen = true;
    }

    @action
    closeLinkEntityCost(): void {
        this.linkEntityCostModalIsOpen = false;
    }

    async setFilters(filters: ExpenseSearchFilters): Promise<void> {
        this.filters = filters;
        this.pagination.reset();

        this.getAllExpenses(this.pagination, this.filters);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.getAllExpenses(this.pagination, this.filters);
    }

    @action
    setExpenseForm(expenseId: number): void {
        this.expenseForm = this._expenses.expenses.find(
            (expense) => expense.id === expenseId,
        );
    }

    @flow
    async *getAllExpenses(
        pagination: Pagination,
        filters: ExpenseSearchFilters,
    ): AsyncGenerator<void> {
        if (!this.projectId) throw new Error("Mising projectId");

        const expenses = await this.getAllExpensesUseCase.execute(pagination, {
            ...filters,
            projectId: this.projectId,
        });

        runInAction(() => {
            this._expenses = new Expenses(expenses, expenses.length);
            this.expenses = expenses;
        });
    }

    async createExpense(
        createFormValues: ExpensesFormValuesValidated,
        projectId: number,
    ): Promise<void> {
        LoadLayoutStore.start();

        await this.createExpenseUseCase.execute(
            this.createExpenseMapper.mapFromFormValues(
                createFormValues,
                projectId,
            ),
        );

        this.getAllExpenses(this.pagination, this.filters);

        LoadLayoutStore.finish();
    }

    async editExpense(
        editFormValues: ExpensesFormValuesValidated,
        expenseId: number,
        projectId: number,
    ): Promise<void> {
        LoadLayoutStore.start();

        await this.editExpenseUseCase.execute(
            this.editExpenseMapper.mapFromFormValues(
                editFormValues,
                expenseId,
                projectId,
            ),
        );

        this.getAllExpenses(this.pagination, this.filters);

        LoadLayoutStore.finish();
    }

    async deleteExpense(expenseId: number): Promise<void> {
        await this.deleteExpenseUseCase.execute(expenseId);
        this.getAllExpenses(this.pagination, this.filters);
    }

    @flow
    async *getAllExpenseTypes(): AsyncGenerator<void> {
        const types = await this.getAllProjectExpenseTypesUseCase.execute();

        runInAction(() => {
            this.types = types;
        });
    }

    async updateCost(
        values: EntityCostFormValuesValidated,
    ): Promise<Nullable<Cost>> {
        if (!values.costId) {
            throw Error("Missing costId");
        }

        const costToUpdate = await this.getCostByIdUseCase.execute(
            values.costId,
        );

        let editCostResult = null;

        if (costToUpdate) {
            LoadLayoutStore.start();

            const additionalExpensesToUpdate = [
                ...(costToUpdate.additionalExpenses ?? []),
            ] as AdditionalExpenseCreate[];

            const additionalExpenses: AdditionalExpenseCreate = {
                project: Number(values.projectId),
                invoice: values.costId,
                amount: values.amountImputation,
            };

            additionalExpensesToUpdate.push(additionalExpenses);

            const costFormValuesValidated: CostFormValuesValidated = {
                id: costToUpdate.id,
                invoiceNum: costToUpdate.invoiceNum,
                concept: costToUpdate.concept,
                socialReason: costToUpdate.issuerIdentificationType,
                identificationType: costToUpdate.issuerIdentificationType,
                identification: costToUpdate.identificationNumber,
                costType: costToUpdate.type,
                otherCostType: costToUpdate.othersType ?? undefined,
                invoiceDate: costToUpdate.invoiceDate as DateTime<boolean>,
                paymentDate: costToUpdate.paymentDate,
                paymentMethod: costToUpdate.paymentMethod,
                base: costToUpdate.base as number,
                iva: costToUpdate.iva,
                totalCost: costToUpdate.total as number,
                documents: costToUpdate.document ? [costToUpdate.document] : [],
                additionalExpensesToUpdate,
                irpfType: costToUpdate.irpfType as IRPFTypeEnum,
            };

            editCostResult = await this.updateCostUseCase.execute(
                this.editCostMapper.mapFromCostFormValues(
                    costFormValuesValidated,
                    costToUpdate.entityId,
                    values.costId,
                ),
            );

            if (editCostResult) {
                ToastManagerStore.success();
                this.linkEntityCostModalIsOpen = false;
            }

            LoadLayoutStore.finish();
        }

        return editCostResult;
    }
}
