import { AssociateServiceToDependence } from "@beneficiary/domain/models/associate-service-to-dependence.enum";
import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { DependenceGrade } from "@beneficiary/domain/models/dependence-grade.enum";
import { DisabilityType } from "@beneficiary/domain/models/disability-type.enum";
import { ExpenseType } from "@beneficiary/domain/models/economic-data/expense/expense-type.model";
import { EditBeneficiary } from "@beneficiary/domain/models/edit-beneficiary.model";
import { EmploymentRelevantCharacteristics } from "@beneficiary/domain/models/employment-situation-relevant-characteristics.enum";
import { Relationship } from "@beneficiary/domain/models/relationship.model";
import { TherapyType } from "@beneficiary/domain/models/therapy-type.enum";
import { DocumentRepository } from "@core/data/repositories/document.repository";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { DiagnosisType } from "@core/domain/models/diagnosis-type.model";
import { Order } from "@core/domain/models/order.model";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";
import { BeneficiariesSummary } from "../../domain/models/beneficiaries-summary.model";
import { BeneficiarySearchFilters } from "../../domain/models/beneficiary-search-filters";
import {
    Beneficiary,
    BeneficiaryIdentification,
} from "../../domain/models/beneficiary.model";
import {
    CreateBeneficiary,
    CreateBeneficiaryIdentification,
} from "../../domain/models/create-beneficiary.model";
import { ExpenseRecurrence } from "../../domain/models/economic-data/expense/expense-recurrence.model";
import { BeneficiaryIdentificationType } from "../../domain/models/identification-document-type.model";
import { BeneficiaryDatasource } from "../datasource/beneficiary.datasource";
import { ExpenseDatasource } from "../datasource/expense.datasource";
@injectable()
export class BeneficiaryRepository {
    private expenseTypes: Undefinable<ExpenseType[]>;
    private expenseRecurrences: Undefinable<ExpenseRecurrence[]>;
    private relationships: Undefinable<Relationship[]>;

    constructor(
        @inject(BeneficiaryDatasource)
        private readonly beneficiaryDatasource: BeneficiaryDatasource,
        @inject(DocumentRepository)
        private readonly documentRepository: DocumentRepository,
        @inject(ExpenseDatasource)
        private readonly expenseDatasource: ExpenseDatasource,
    ) {}

    async create(
        createBeneficiary: CreateBeneficiary,
    ): Promise<Either<ValidationError | FallbackError, Beneficiary>> {
        const createdBeneficiary =
            await this.beneficiaryDatasource.create(createBeneficiary);

        const beneficiaryResult = createdBeneficiary.fold(
            () => null,
            (beneficiary) => beneficiary,
        );
        if (beneficiaryResult?.identifications) {
            await Promise.all(
                createBeneficiary.identifications.map(async (identification) =>
                    this.createIdentification(
                        identification,
                        beneficiaryResult.id,
                    ),
                ),
            );
        }

        return createdBeneficiary;
    }

    async createIdentification(
        createIdentification: CreateBeneficiaryIdentification,
        beneficiaryId: number,
    ): Promise<BeneficiaryIdentification> {
        const createdBeneficiaryIdentification =
            await this.beneficiaryDatasource.createIdentification(
                createIdentification,
                beneficiaryId,
            );

        const beneficiaryIdentification = createdBeneficiaryIdentification.fold(
            () => null,
            (beneficiaryIde) => beneficiaryIde,
        );
        if (!beneficiaryIdentification) {
            throw new FallbackError();
        }
        return beneficiaryIdentification;
    }

    async getAllBy(
        pagination: Pagination,
        filters?: BeneficiarySearchFilters,
        order?: Order<BeneficiarySummary>,
    ): Promise<Either<FallbackError, BeneficiariesSummary>> {
        const beneficiariesSummary = await this.beneficiaryDatasource.fetchBy(
            pagination,
            filters,
            order,
        );

        return beneficiariesSummary.mapLeft(() => new FallbackError());
    }

    async getRecentModified(): Promise<
        Either<FallbackError, BeneficiariesSummary>
    > {
        const recentSize = 5;
        const pagination: Pagination = new Pagination(0, recentSize);

        const order = new Order<BeneficiarySummary>("modified", "desc");

        const beneficiariesSummary = await this.getAllBy(
            pagination,
            undefined,
            order,
        );

        return beneficiariesSummary.mapLeft(() => new FallbackError());
    }

    async findIdentificationsById(
        beneficiaryId: number,
    ): Promise<Either<FallbackError, BeneficiaryIdentification[]>> {
        const beneficiaryIdentificationResult =
            await this.beneficiaryDatasource.fetchIdentificationBy({
                beneficiaryId,
            });

        if (beneficiaryIdentificationResult.isLeft()) {
            return Either.Left(
                beneficiaryIdentificationResult.getLeftOrThrow(),
            );
        }

        return beneficiaryIdentificationResult;
    }

    async findById(
        beneficiaryId: number,
    ): Promise<Either<FallbackError, Beneficiary>> {
        const beneficiaryResult =
            await this.beneficiaryDatasource.fetchById(beneficiaryId);

        if (beneficiaryResult.isLeft())
            return Either.Left(beneficiaryResult.getLeftOrThrow());

        const { beneficiary, documentIds } = beneficiaryResult.getOrThrow();
        const documentsResults = await Promise.all(
            documentIds.map(async (documentId) =>
                this.documentRepository.getById(documentId),
            ),
        );

        beneficiary.documentsData = documentsResults.mapNotNull(
            (documentResult) =>
                documentResult.isRight() ? documentResult.getOrThrow() : null,
        );

        const beneficiaryIdentifications =
            await this.findIdentificationsById(beneficiaryId);

        beneficiary.identifications = beneficiaryIdentifications.getOrThrow();

        return Either.Right(beneficiary);
    }

    async edit(
        editBeneficiary: EditBeneficiary,
    ): Promise<Either<ValidationError | FallbackError, Beneficiary>> {
        const updatedBeneficiary =
            await this.beneficiaryDatasource.update(editBeneficiary);
        if (updatedBeneficiary.isLeft()) {
            throw new FallbackError();
        }

        const beneficiaryIdentifications = await this.findIdentificationsById(
            editBeneficiary.id,
        );
        if (beneficiaryIdentifications.isLeft()) {
            throw new FallbackError();
        }
        const getBeneficiaryIdentifications =
            beneficiaryIdentifications.getOrThrow();
        const editIdentificationsMap = new Map<
            number,
            BeneficiaryIdentification
        >(
            editBeneficiary.identificationsEdit.map((identification) => [
                identification.id,
                identification,
            ]),
        );

        if (editBeneficiary.identificationsCreate.isNotEmpty()) {
            await Promise.all(
                editBeneficiary.identificationsCreate.map(
                    async (identification) =>
                        this.createIdentification(
                            identification,
                            editBeneficiary.id,
                        ),
                ),
            );
        }

        if (editBeneficiary.identificationsEdit.isNotEmpty()) {
            await Promise.all(
                editBeneficiary.identificationsEdit.map(
                    async (identification) =>
                        this.editIdentification(identification),
                ),
            );
        }

        const deleteIdentifications = getBeneficiaryIdentifications.filter(
            (identification) => !editIdentificationsMap.get(identification.id),
        );
        if (deleteIdentifications.isNotEmpty()) {
            await Promise.all(
                deleteIdentifications.map(async (identification) =>
                    this.deleteIdentification(identification.id),
                ),
            );
        }

        return updatedBeneficiary;
    }

    async editIdentification(
        editBeneficiaryIdentification: BeneficiaryIdentification,
    ): Promise<
        Either<ValidationError | FallbackError, BeneficiaryIdentification>
    > {
        return this.beneficiaryDatasource.updateIdentification(
            editBeneficiaryIdentification,
        );
    }

    async deleteIdentification(
        identificationId: number,
    ): Promise<Either<FallbackError, boolean>> {
        return this.beneficiaryDatasource.deleteIdentification(
            identificationId,
        );
    }

    async getAllExpenseTypes(): Promise<Either<FallbackError, ExpenseType[]>> {
        if (this.expenseTypes) return Either.Right(this.expenseTypes);

        const expenseTypesResult =
            await this.expenseDatasource.fetchAllExpenseTypes();

        if (expenseTypesResult.isRight()) {
            this.expenseTypes = expenseTypesResult.getOrThrow();
        }

        return expenseTypesResult;
    }

    async editAddress(
        beneficiaryId: number,
        usualAddressId?: Undefinable<number>,
        censusAddressId?: Undefinable<number>,
    ): Promise<Either<FallbackError, Beneficiary>> {
        return this.beneficiaryDatasource.updateAddress(
            beneficiaryId,
            usualAddressId,
            censusAddressId,
        );
    }

    async getAllExpenseRecurrences(): Promise<
        Either<FallbackError, ExpenseRecurrence[]>
    > {
        if (this.expenseRecurrences)
            return Either.Right(this.expenseRecurrences);

        const expenseRecurrencesResult =
            await this.beneficiaryDatasource.fetchAllExpenseRecurrences();

        if (expenseRecurrencesResult.isRight()) {
            this.expenseRecurrences = expenseRecurrencesResult.getOrThrow();
        }

        return expenseRecurrencesResult;
    }

    async getAllRelationships(): Promise<
        Either<FallbackError, Relationship[]>
    > {
        if (this.relationships) return Either.Right(this.relationships);

        const relationshipResult =
            await this.beneficiaryDatasource.fetchAllRelationShips();

        if (relationshipResult.isRight()) {
            this.relationships = relationshipResult.getOrThrow();
        }

        return relationshipResult;
    }

    async getAllIdentificationTypes(): Promise<
        Either<FallbackError, BeneficiaryIdentificationType[]>
    > {
        return this.beneficiaryDatasource.fetchAllBeneficiaryIdentificationTypes();
    }

    async getAllDisabilityTypes(): Promise<
        Either<FallbackError, DisabilityType[]>
    > {
        return this.beneficiaryDatasource.fetchAllDisabilityTypes();
    }

    async getAllDependenceGrades(): Promise<
        Either<FallbackError, DependenceGrade[]>
    > {
        return this.beneficiaryDatasource.fetchAllDependenceGrades();
    }

    async getAllTherapyTypes(): Promise<Either<FallbackError, TherapyType[]>> {
        return this.beneficiaryDatasource.fetchAllTherapyTypes();
    }

    async getAllAssociateServiceToDependence(): Promise<
        Either<FallbackError, AssociateServiceToDependence[]>
    > {
        return this.beneficiaryDatasource.fetchAllAssociateServiceToDependence();
    }

    async getAllOtherEmploymentCharcteristics(): Promise<
        Either<FallbackError, EmploymentRelevantCharacteristics[]>
    > {
        return this.beneficiaryDatasource.fetchAllOtherEmploymentCharcteristics();
    }

    async getAllDiagnosisTypes(): Promise<
        Either<FallbackError, DiagnosisType[]>
    > {
        return this.beneficiaryDatasource.fetchAllDiagnosisTypes();
    }
}
