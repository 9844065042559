import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { DateTime } from "luxon";
import { CostTypeEnum } from "./cost-type.model";

export interface CostSearchFilters {
    search?: Nullish<string>;
    type?: Nullish<CostTypeEnum>;
    costFromDate?: Nullish<DateTime>;
    costToDate?: Nullish<DateTime>;
    entityIds?: Nullish<string[]>;
}

export class CostsSummary {
    constructor(
        public readonly costsSummary: CostSummary[],
        public readonly count: number,
    ) {}
}

export class CostSummary {
    constructor(
        readonly id: number,
        readonly entityId: number,
        readonly invoiceNum: string,
        readonly type: CostTypeEnum,
        readonly concept: string,
        readonly invoiceDate: Nullable<DateTime>,
    ) {}
}
