import { CreateBeneficiaryMapper } from "@beneficiary/data/mappers/create-beneficiary.mapper";
import { Beneficiary } from "@beneficiary/domain/models/beneficiary.model";
import { CreateBeneficiaryUseCase } from "@beneficiary/domain/usecases/create-beneficiary.usecase";
import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";
import { CreateBeneficiaryFormValuesValidated } from "../../components/beneficiary-form/beneficiary-form.component";

@injectable()
export class CreateBeneficiaryPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateBeneficiaryUseCase)
        private readonly createBeneficiaryUseCase: CreateBeneficiaryUseCase,
        private readonly createBeneficiaryMapper: CreateBeneficiaryMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createBeneficiary(
        values: CreateBeneficiaryFormValuesValidated,
    ): Promise<Nullable<Beneficiary>> {
        LoadLayoutStore.start();

        const createBeneficiaryResult =
            await this.createBeneficiaryUseCase.execute(
                this.createBeneficiaryMapper.mapFromBeneficiaryFormValues(
                    values,
                ),
            );

        if (createBeneficiaryResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createBeneficiaryResult;
    }
}
