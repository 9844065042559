import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Either } from "@core/domain/types/either";
import {
    ContractTerminationDto,
    ContractTerminationsDto,
} from "@entity/data/dto/employee/contracts/termination/contract-termination.dto";
import { CreateContractTerminationBody } from "@entity/data/dto/employee/contracts/termination/create-contract-termination.body";
import { EditContractTerminationBody } from "@entity/data/dto/employee/contracts/termination/edit-contract-termination.body";
import { TerminationReasonDto } from "@entity/data/dto/employee/termination-reason.dto";
import { ContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/contract-termination.mapper";
import { ContractTerminationsMapper } from "@entity/data/mappers/employee/contracts/termination/contract-terminations.mapper";
import { CreateContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/create-contract-termination.mapper";
import { EditContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/edit-contract-termination.mapper";
import { TerminationReasonMapper } from "@entity/data/mappers/employee/termination-reason.mapper";
import {
    ContractTermination,
    ContractTerminations,
} from "@entity/domain/models/employee/contracts/termination/contract-termination.model";
import { CreateContractTermination } from "@entity/domain/models/employee/contracts/termination/create-contract-termination.model";
import { EditContractTermination } from "@entity/domain/models/employee/contracts/termination/edit-contract-termination.model";
import { TerminationReason } from "@entity/domain/models/employee/termination-reason.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const CONTRACTS_TERMINATION_PATH = "/employee_contracts_termination/";

@injectable()
export class ContractTerminationDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(ContractTerminationMapper)
        private readonly contractTerminationMapper: ContractTerminationMapper,
        @inject(ContractTerminationsMapper)
        private readonly contractTerminationsMapper: ContractTerminationsMapper,
        @inject(CreateContractTerminationMapper)
        private readonly createContractTerminationMapper: CreateContractTerminationMapper,
        @inject(EditContractTerminationMapper)
        private readonly editContractTerminationMapper: EditContractTerminationMapper,
        @inject(TerminationReasonMapper)
        private readonly terminationReasonMapper: TerminationReasonMapper,
    ) {}

    async fetchAllTerminationReasons(): Promise<
        Either<FallbackError, TerminationReason[]>
    > {
        const responseResult = await this.http.get<TerminationReasonDto[]>(
            "/employee_contracts_termination/termination_reasons/",
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((terminationReasonDto) =>
                    this.terminationReasonMapper.map(
                        plainToClass(
                            TerminationReasonDto,
                            terminationReasonDto,
                        ),
                    ),
                ),
            );
    }

    async fetchAllContractTerminations(): Promise<
        Either<FallbackError, ContractTerminations>
    > {
        const responseResult = await this.http.get<ContractTerminationDto>(
            CONTRACTS_TERMINATION_PATH,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const employeeContracts = this.contractTerminationsMapper.map(
                    plainToClass(ContractTerminationsDto, response.data),
                );

                return Either.Right(employeeContracts);
            });
    }

    async createContractTermination(
        newContractTermination: CreateContractTermination,
    ): Promise<Either<ValidationError | FallbackError, ContractTermination>> {
        const contractTerminationBody =
            this.createContractTerminationMapper.mapToCreateDto(
                newContractTermination,
            );

        const result = await this.http.post<
            ContractTerminationDto,
            CreateContractTerminationBody
        >(CONTRACTS_TERMINATION_PATH, contractTerminationBody);

        return result
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const contract = this.contractTerminationsMapper.mapContract(
                    plainToClass(ContractTerminationDto, response.data),
                );

                if (!contract) return Either.Left(new FallbackError());

                return Either.Right(contract);
            });
    }

    async updateContractTermination(
        contractTermination: EditContractTermination,
    ): Promise<Either<ValidationError | FallbackError, ContractTermination>> {
        const editedContractTerminationDto =
            this.editContractTerminationMapper.mapToDto(contractTermination);

        const editContractTerminationResult = await this.http.patch<
            ContractTerminationDto,
            EditContractTerminationBody
        >(
            `${CONTRACTS_TERMINATION_PATH}${contractTermination.id}/`,
            editedContractTerminationDto,
        );

        return editContractTerminationResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const volunteerContractEdited =
                    this.contractTerminationMapper.map(
                        plainToClass(ContractTerminationDto, response.data),
                    );
                if (!volunteerContractEdited)
                    return Either.Left(new FallbackError());

                return Either.Right(volunteerContractEdited);
            });
    }

    async deleteContractTermination(
        contractTerminationId: number,
    ): Promise<Either<FallbackError, true>> {
        const result = await this.http.delete(
            `${CONTRACTS_TERMINATION_PATH}${contractTerminationId}`,
        );

        return result.mapLeft(() => new FallbackError()).map(() => true);
    }
}
