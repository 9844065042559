import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum CostTypeEnumDto {
    freelance = "freelance",
    supply = "supply",
    local = "local",
    activity = "activity",
    maintenance = "maintenance",
    supplier = "supplier",
    benefit_INVOICE = "benefit_INVOICE",
    info_INVOICE = "info_INVOICE",
    license = "license",
    furniture = "furniture",
    fungible = "fungible",
    admin = "admin",
    audit = "audit",
    isos = "isos",
    other = "other",
}

export class CostTypeDto {
    @IsEnum(CostTypeEnumDto)
    @IsNotEmpty()
    key!: CostTypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
