import { Undefinable } from "@core/domain/types/undefinable.type";
import { Entity } from "@entity/domain/models/entity.model";

export class CreateEntity {
    public otherCollective?: Undefinable<string>;
    public identificationDocument?: Entity["identification"];
    public cnaeCode?: Entity["cnaeCode"];
    public otherTypology?: Undefinable<string>;
    public otherActionLine?: Undefinable<string>;
    public actionLinesDescription?: Undefinable<string>;
    public otherCrosscuttingScope?: Undefinable<string>;
    public entityPurpose?: Entity["entityPurpose"];
    public webAddress?: Entity["webAddress"];
    public legalConstitutionDate?: Entity["legalConstitutionDate"];
    public publicInterest?: Entity["publicInterest"];
    public equalityPlan?: Entity["equalityPlan"];
    public equalityPlanExpirationDate?: Entity["equalityPlanExpirationDate"];

    constructor(
        public name: Entity["name"],
        public typologyId: Entity["typologyId"],
        public categoryId: Entity["categoryId"],
        public collectiveId: Entity["collectiveId"],
        public actionLines: Entity["actionLines"],
        public secondaryCollectives: Entity["secondaryCollectives"],
        public crosscuttingScopes?: Entity["crosscuttingScopes"],
    ) {}
}
