import { Cost } from "./cost.model";

export class CreateCost {
    constructor(
        readonly entityId: Cost["entityId"],
        readonly invoiceNum: Cost["invoiceNum"],
        readonly concept: Cost["concept"],
        readonly issuerName: Cost["issuerName"],
        readonly issuerIdentificationType: Cost["issuerIdentificationType"],
        readonly identificationNumber: Cost["identificationNumber"],
        readonly type: Cost["type"],
        readonly invoiceDate: Cost["invoiceDate"],
        readonly paymentDate: Cost["paymentDate"],
        readonly paymentMethod: Cost["paymentMethod"],
        readonly base: Cost["base"],
        readonly iva: Cost["iva"],
        readonly discount: Cost["discount"],
        readonly total: Cost["total"],
        readonly irpfType: Cost["irpfType"],
        readonly othersType?: Cost["othersType"],
        readonly otherPaymentMethod?: Cost["otherPaymentMethod"],
        readonly document?: Cost["document"],
        readonly additionalExpensesToUpdate?: Cost["additionalExpensesToUpdate"],
    ) {}
}
