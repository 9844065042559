import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum TerminationReasonEnumDto {
    resignation = "resignation",
    disciplinary_dismissal = "disciplinary_dismissal",
    objective_dismissal = "objective_dismissal",
    collective_dismissal = "collective_dismissal",
    improper_dismissal = "improper_dismissal",
    temporary_leave = "temporary_leave",
    death = "death",
}

export class TerminationReasonDto {
    @IsEnum(TerminationReasonEnumDto)
    @IsNotEmpty()
    key!: TerminationReasonEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
