import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";
import { DepositTypeEnum } from "../../domain/models/deposit-type.model";
import { DepositTypeEnumDto } from "../dto/deposit-types.dto";

@injectable()
export class DepositTypeEnumMapper {
    map(depositTypeEnumDto: DepositTypeEnumDto): Nullable<DepositTypeEnum> {
        switch (depositTypeEnumDto) {
            case DepositTypeEnumDto.PRIVATE_GRANT:
                return DepositTypeEnum.PRIVATE_GRANT;
            case DepositTypeEnumDto.FUNDING_AGENCY:
                return DepositTypeEnum.FUNDING_AGENCY;
            case DepositTypeEnumDto.DONATION:
                return DepositTypeEnum.DONATION;
            case DepositTypeEnumDto.FUNDRAISING_CAMPAIGN:
                return DepositTypeEnum.FUNDRAISING_CAMPAIGN;
            case DepositTypeEnumDto.PUBLIC_ADMINISTRATION_CONTRACT:
                return DepositTypeEnum.PUBLIC_ADMINISTRATION_CONTRACT;
            case DepositTypeEnumDto.PRIVATE_ENTITY_CONTRACT:
                return DepositTypeEnum.PRIVATE_ENTITY_CONTRACT;
            case DepositTypeEnumDto.MERCH:
                return DepositTypeEnum.MERCH;
            case DepositTypeEnumDto.MEMBERSHIP_FEES:
                return DepositTypeEnum.MEMBERSHIP_FEES;
            case DepositTypeEnumDto.OTHER:
                return DepositTypeEnum.OTHER;
            default:
                return null;
        }
    }

    mapToDto(depositTypeEnum: DepositTypeEnum): DepositTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (depositTypeEnum) {
            case DepositTypeEnum.PRIVATE_GRANT:
                return DepositTypeEnumDto.PRIVATE_GRANT;
            case DepositTypeEnum.FUNDING_AGENCY:
                return DepositTypeEnumDto.FUNDING_AGENCY;
            case DepositTypeEnum.DONATION:
                return DepositTypeEnumDto.DONATION;
            case DepositTypeEnum.FUNDRAISING_CAMPAIGN:
                return DepositTypeEnumDto.FUNDRAISING_CAMPAIGN;
            case DepositTypeEnum.PUBLIC_ADMINISTRATION_CONTRACT:
                return DepositTypeEnumDto.PUBLIC_ADMINISTRATION_CONTRACT;
            case DepositTypeEnum.PRIVATE_ENTITY_CONTRACT:
                return DepositTypeEnumDto.PRIVATE_ENTITY_CONTRACT;
            case DepositTypeEnum.MERCH:
                return DepositTypeEnumDto.MERCH;
            case DepositTypeEnum.MEMBERSHIP_FEES:
                return DepositTypeEnumDto.MEMBERSHIP_FEES;
            case DepositTypeEnum.OTHER:
                return DepositTypeEnumDto.OTHER;
        }
    }
}
