import { Nullable } from "@core/domain/types/nullable.type";
import { IVATypeEnum } from "@entity/domain/models/cost/iva-type.model";
import { injectable } from "inversify";
import { IVATypeEnumDto } from "../../dto/iva-type.dto";

@injectable()
export class IVAEnumMapper {
    map(ivaTypeDto: IVATypeEnumDto): Nullable<IVATypeEnum> {
        switch (ivaTypeDto) {
            case IVATypeEnumDto.Cero:
                return IVATypeEnum.Cero;
            case IVATypeEnumDto.Five:
                return IVATypeEnum.Five;
            case IVATypeEnumDto.Ten:
                return IVATypeEnum.Ten;
            case IVATypeEnumDto.TwentyOne:
                return IVATypeEnum.TwentyOne;
            default:
                return null;
        }
    }

    mapToDto(ivaType: IVATypeEnum): Nullable<IVATypeEnumDto> {
        switch (ivaType) {
            case IVATypeEnum.Cero:
                return IVATypeEnumDto.Cero;
            case IVATypeEnum.Five:
                return IVATypeEnumDto.Five;
            case IVATypeEnum.Ten:
                return IVATypeEnumDto.Ten;
            case IVATypeEnum.TwentyOne:
                return IVATypeEnumDto.TwentyOne;
            default:
                return null;
        }
    }
}
