import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { CategoryTypeEnum } from "./category-type.enum";

export interface EntitySearchFilters {
    name?: string;
    serviceIds?: number[];
    collectiveIds?: number[];
    categoryType?: Nullable<CategoryTypeEnum>;
    active?: boolean;
    beneficiaryId?: number;
}

export class EntitiesSummary {
    constructor(
        public readonly entitiesSummary: EntitySummary[],
        public readonly count: number,
    ) {}
}

export class EntitySummary {
    constructor(
        public readonly id: number,
        public name: string,
        public identification: Undefinable<string>,
        public category: Undefinable<CategoryTypeEnum>,
        public readonly categoryLabel: Undefinable<string>,
    ) {}
}
