import { DocumentDto } from "@core/data/dto/document.dto";
import { IdentificationDocumentTypeEnumDto } from "@core/data/dto/identification-document-type.dto";
import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { OrderField } from "@core/domain/models/order.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";
import { DepositTypeEnum } from "../../domain/models/deposit-type.model";
import { Deposit } from "../../domain/models/deposit.model";
import { IdentificationTypeEnumDto } from "./identification-type.dto";

export class CreateDepositDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    concept!: string;

    @IsEnum(DepositTypeEnum)
    @IsNotEmpty()
    type!: DepositTypeEnum;

    @IsString()
    @IsOptional()
    others_type!: Nullable<string>;

    @IsString()
    @IsOptional()
    observations!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    amount!: number;

    @IsDateString()
    @IsNotEmpty()
    deposit_date!: string;

    @IsDateString()
    @IsOptional()
    acquisition_date!: Nullable<string>;

    @IsEnum(IdentificationDocumentTypeEnumDto)
    @IsOptional()
    identification_type!: Nullable<IdentificationTypeEnumDto>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsString()
    @IsOptional()
    street!: Nullable<string>;

    @IsString()
    @IsOptional()
    zip_code!: Nullable<string>;

    @IsString()
    @IsOptional()
    town!: Nullable<string>;

    @IsString()
    @IsOptional()
    province!: Nullable<string>;

    @IsString()
    @IsOptional()
    grants!: Nullable<string>;

    @IsString()
    @IsOptional()
    call_year!: Nullable<string>;

    @IsString()
    @IsOptional()
    execution_year!: Nullable<string>;

    @IsString()
    @IsOptional()
    call_status!: Nullable<string>;

    @IsString()
    @IsOptional()
    name!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsNumber()
    @IsOptional()
    project!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    document!: Nullable<number>;
}

export class SplitDepositDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsNumber()
    @IsNotEmpty()
    deposit!: number;

    @IsNumber()
    @IsNotEmpty()
    assigned_percentage!: number;
}

export class CreateSplitDepositDto {
    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsNumber()
    @IsNotEmpty()
    deposit!: number;

    @IsNumber()
    @IsNotEmpty()
    assigned_percentage!: number;
}

export class DepositDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    concept!: string;

    @IsEnum(DepositTypeEnum)
    @IsNotEmpty()
    type!: DepositTypeEnum;

    @IsString()
    @IsOptional()
    others_type!: Nullable<string>;

    @IsString()
    @IsOptional()
    observations!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    amount!: number;

    @IsDateString()
    @IsNotEmpty()
    deposit_date!: string;

    @IsDateString()
    @IsOptional()
    acquisition_date!: Nullable<string>;

    @IsEnum(IdentificationTypeEnumDto)
    @IsOptional()
    identification_type!: Nullable<IdentificationTypeEnumDto>;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsString()
    @IsOptional()
    street!: Nullable<string>;

    @IsString()
    @IsOptional()
    zip_code!: Nullable<string>;

    @IsString()
    @IsOptional()
    town!: Nullable<string>;

    @IsString()
    @IsOptional()
    province!: Nullable<string>;

    @IsString()
    @IsOptional()
    grants!: Nullable<string>;

    @IsString()
    @IsOptional()
    call_year!: Nullable<string>;

    @IsString()
    @IsOptional()
    execution_year!: Nullable<string>;

    @IsString()
    @IsOptional()
    call_status!: Nullable<string>;

    @IsString()
    @IsOptional()
    name!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsNumber()
    @IsOptional()
    project!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    document!: Nullable<number>;

    @IsOptional()
    @Type(() => DocumentDto)
    document_data!: Nullable<DocumentDto>;

    @IsOptional()
    @Type(() => SplitDepositDto)
    split_deposit_data!: Nullable<SplitDepositDto[]>;
}

export class DepositResultDto extends DepositDto {
    @IsArray()
    @Type(() => DepositDto)
    results!: DepositDto[];
}

export class DepositsDto extends PaginatedDto {
    @IsArray()
    @Type(() => DepositDto)
    results!: DepositDto[];
}

export const depositOrderMap: Record<
    OrderField<Deposit>,
    Nullable<OrderFieldsQuery<DepositDto>>
> = {
    id: "id",
    name: "name",
    type: "type",
    modified: null,
    concept: null,
    amount: null,
    depositDate: null,
    identificationType: null,
    identification: null,
    street: null,
    zipCode: null,
    town: null,
    province: null,
    grants: null,
    callYear: null,
    executionYear: null,
    callStatus: null,
    entity: null,
    relatedProject: null,
    relatedDocument: null,
    acquisitionDate: null,
    othersType: null,
    observations: null,
    attachedDocumentName: null,
    splitDeposits: null,
};

export type DepositQuery = PaginatedQuery & {
    search?: Nullish<string>;
    name?: Nullish<string>;
    type?: Nullable<DepositTypeEnum>;
    deposit_date__gte?: Nullable<string>;
    deposit_date__lte?: Nullable<string>;
    ordering?: OrderQuery<DepositDto>;
};
