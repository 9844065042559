import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { DateTime } from "luxon";
import { IdentificationTypeEnum } from "../identification-type.model";
import { CostTypeEnum } from "./cost-type.model";
import { IRPFTypeEnum } from "./irpf-type.model";
import { IVATypeEnum } from "./iva-type.model";
import { PaymentMethodTypeEnum } from "./payment-method-type.model";

export class Cost {
    constructor(
        readonly id: number,
        readonly entityId: number,
        readonly invoiceNum: string,
        readonly concept: string,
        readonly issuerName: string,
        readonly issuerIdentificationType: IdentificationTypeEnum,
        readonly identificationNumber: string,
        readonly type: CostTypeEnum,
        readonly invoiceDate: Nullable<DateTime>,
        readonly paymentDate: Nullable<DateTime>,
        readonly paymentMethod: PaymentMethodTypeEnum,
        readonly base: Nullable<number>,
        readonly iva: IVATypeEnum,
        readonly total: Nullable<number>,
        readonly discount: Nullable<number>,
        readonly irpfType: Nullable<IRPFTypeEnum>,
        readonly othersType?: Nullable<string>,
        readonly otherPaymentMethod?: Nullable<string>,
        readonly document?: Nullable<IncDocument>,
        readonly additionalExpensesToUpdate?: AdditionalExpenseCreate[],
        readonly additionalExpenses?: AdditionalExpense[],
    ) {}
}

export class AdditionalExpense {
    constructor(
        readonly id: number,
        readonly project: number,
        readonly invoice: number,
        readonly amount: number,
    ) {}
}

export class AdditionalExpenseCreate {
    constructor(
        readonly project: number,
        readonly invoice: number,
        readonly amount: number,
        readonly id?: number,
    ) {}
}
