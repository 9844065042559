import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DepositTypeEnum } from "@entity/domain/models/deposit-type.model";
import { IdentificationTypeEnum } from "./identification-type.model";

export class SplitDeposit {
    constructor(
        public readonly id: number,
        public depositId: number,
        public projectId: number,
        public assignedPercentage: number,
    ) {}
}

export class CreateSplitDeposit {
    constructor(
        public depositId: number,
        public projectId: number,
        public assignedPercentage: number,
    ) {}
}

export class CreateDeposit {
    // eslint-disable-next-line max-params
    constructor(
        public concept: string,
        public type: DepositTypeEnum,
        public amount: number,
        public name: string,
        public depositDate: string,
        public identificationType: Nullable<IdentificationTypeEnum>,
        public identification: Nullable<string>,
        public street: Nullable<string>,
        public zipCode: Nullable<string>,
        public town: Nullable<string>,
        public province: Nullable<string>,
        public grants: string,
        public callYear: string,
        public executionYear: string,
        public callStatus: string,
        public entity: number,
        public relatedProject?: number,
        public relatedDocument?: number,
        public acquisitionDate?: string,
        public othersType?: string,
        public observations?: string,
        public attachedDocumentName?: string,
    ) {}
}

export class Deposit extends CreateDeposit {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        concept: CreateDeposit["concept"],
        type: CreateDeposit["type"],
        amount: CreateDeposit["amount"],
        name: CreateDeposit["name"],
        depositDate: CreateDeposit["depositDate"],
        identificationType: CreateDeposit["identificationType"],
        identification: CreateDeposit["identification"],
        street: CreateDeposit["street"],
        zipCode: CreateDeposit["zipCode"],
        town: CreateDeposit["town"],
        province: CreateDeposit["province"],
        grants: CreateDeposit["grants"],
        callYear: CreateDeposit["callYear"],
        executionYear: CreateDeposit["executionYear"],
        callStatus: CreateDeposit["callStatus"],
        entity: CreateDeposit["entity"],
        relatedProject: CreateDeposit["relatedProject"],
        relatedDocument: CreateDeposit["relatedDocument"],
        acquisitionDate: CreateDeposit["acquisitionDate"],
        othersType: CreateDeposit["othersType"],
        observations: CreateDeposit["observations"],
        attachedDocumentName: CreateDeposit["attachedDocumentName"],
        public splitDeposits?: Undefinable<SplitDeposit[]>,
    ) {
        super(
            concept,
            type,
            amount,
            name,
            depositDate,
            identificationType,
            identification,
            street,
            zipCode,
            town,
            province,
            grants,
            callYear,
            executionYear,
            callStatus,
            entity,
            relatedProject,
            relatedDocument,
            acquisitionDate,
            othersType,
            observations,
            attachedDocumentName,
        );
    }
}
