import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { CategoryTypeEnumDto } from "@entity/data/dto/category-type-enum.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class EntitySummaryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    company_name!: string;

    @IsString()
    @IsOptional()
    identification!: Nullable<string>;

    @IsEnum(CategoryTypeEnumDto)
    @IsOptional()
    category!: Nullable<CategoryTypeEnumDto>;

    @IsString()
    @IsOptional()
    category_display_name!: Nullable<string>;
}

export class EntitiesSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => EntitySummaryDto)
    results!: EntitySummaryDto[];
}

export type EntitiesSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    identification?: Nullish<string>;
    is_active?: Nullish<boolean>;
    category?: Nullish<CategoryTypeEnumDto>;
    // Comma separated ids
    services?: Nullish<string>;
    // Comma separated ids
    collectives?: Nullish<string>;
};
