import { DocumentDto } from "@core/data/dto/document.dto";
import { OrderFieldsQuery, OrderQuery } from "@core/data/dto/order.query";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import { OrderField } from "@core/domain/models/order.model";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { ProjectSummary } from "@project/domain/models/project-summary.model";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    ValidateNested,
} from "class-validator";
import { CatalogueEnumDto } from "./catalogue.dto";
import { FinancialEntityDto } from "./financial-entity/financial-entities.dto";
import { FinancialEntityTypeEnumDto } from "./financial-entity/financial-entity-type.dto";
import { StatusEnumDto } from "./status.dto";

export class ProjectSummaryDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;

    @IsEnum(CatalogueEnumDto, { each: true })
    @IsNotEmpty()
    catalogue!: CatalogueEnumDto[];

    @IsEnum(StatusEnumDto)
    @IsNotEmpty()
    status!: StatusEnumDto;

    @IsDateString()
    @IsOptional()
    start_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    design_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    submission_deadline!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    submission_design_deadline_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    design_resolution_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    justification_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    reformulation_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    call_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    reformulation_deadline_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    total_cost!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    entity!: number;

    @IsArray()
    @IsOptional()
    @IsNumber(undefined, { each: true })
    materials!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @IsNumber(undefined, { each: true })
    finantial_entity!: Nullable<number[]>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsArray()
    @ValidateNested({ each: true })
    @IsOptional()
    // @Type(() => ) TODO: Add type when working with this types
    materials_data!: Nullable<unknown[]>; // TODO Add type when working with recursos materiales and when the dto is created

    @IsArray()
    @ValidateNested({ each: true })
    @IsOptional()
    // @Type(() => ) TODO: Add type when working with this types
    employees_data!: Nullable<unknown[]>; // TODO Project EmployeesDataDto

    @IsArray()
    @IsOptional()
    @Type(() => FinancialEntityDto)
    finantial_entity_data!: Nullable<FinancialEntityDto[]>;

    @IsNumber()
    @IsNotEmpty()
    year!: number;
}

export class ProjectsSummaryDto extends PaginatedDto {
    @IsArray()
    @Type(() => ProjectSummaryDto)
    results!: ProjectSummaryDto[];
}

export const projectsSummaryOrderMap: Record<
    OrderField<ProjectSummary>,
    Nullable<OrderFieldsQuery<ProjectSummaryDto>>
> = {
    id: "id",
    name: "name",
    financialEntityTypeLabels: "finantial_entity",
    statusLabel: "status",
    modified: "modified",
    catalogues: "catalogue",
    year: "year",
};

export type ProjectsSummaryQuery = PaginatedQuery & {
    search?: Nullish<string>;
    catalogue?: Nullish<CatalogueEnumDto>;
    status?: Nullish<StatusEnumDto>;
    start_date?: Nullish<string>;
    end_date?: Nullish<string>;
    active_from_date?: Nullish<string>;
    active_to_date?: Nullish<string>;
    entity?: Nullish<string>;
    finantial_entity_type?: Nullish<FinancialEntityTypeEnumDto>;
    ordering?: OrderQuery<ProjectSummaryDto>;
    status_in?: Nullish<string>;
    years?: Nullish<string>;
    materials?: Nullish<string>;
    collectives?: Nullish<string>;
    territorial_scope?: Nullish<string>;
    is_active?: Nullish<boolean>;
};
