// eslint-disable-next-line import/no-named-as-default
import i18n, { TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";

// Yup translate
export const buildYupLocale = (translator: TFunction): void => {
    setLocale({
        array: {
            min: ({ min }) =>
                translator("validation:array.min", { count: min }),
        },
        mixed: {
            required: () => translator("validation:mixed.required"),
            defined: () => translator("validation:mixed.defined"),
            notNull: () => translator("validation:mixed.nonNullable"),
        },
        string: {
            email: () => translator("validation:string.email"),
            url: () => translator("validation:string.url"),
            length: ({ length }) =>
                translator("validation:string.length", { length }),
            min: ({ min }) => translator("validation:string.min", { min }),
        },
        number: {
            min: ({ min }) => translator("validation:number.min", { min }),
            max: ({ max }) => translator("validation:number.max", { max }),
            lessThan: ({ less }) =>
                translator("validation:number.lessThan", { less }),
            moreThan: ({ more }) =>
                translator("validation:number.moreThan", { more }),
            positive: () => translator("validation:number.positive"),
            negative: () => translator("validation:number.negative"),
            integer: () => translator("validation:number.integer"),
        },
    });
};

// TODO https://github.com/edvardchen/eslint-plugin-i18next

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            load: "languageOnly", // Busca solo por es en lugar de es-Mx
            debug: process.env.NODE_ENV === "development",
            fallbackLng: "es",
            ns: [
                "component",
                "private",
                "public",
                "validation",
                "error",
                "common",
            ],
            defaultNS: "public",
            returnNull: false,
            interpolation: {
                escapeValue: false,
            },
        },
        (_error, translator) => {
            buildYupLocale(translator);
        },
    );

export default i18n;
