import { BeneficiaryDatasource } from "@beneficiary/data/datasource/beneficiary.datasource";
import { CountriesDatasource } from "@beneficiary/data/datasource/country.datasource";
import { DistrictDatasource } from "@beneficiary/data/datasource/district.datasource";
import { ExternalDerivationDatasource } from "@beneficiary/data/datasource/external-derivation.datasource";
import { InternalNotesDatasource } from "@beneficiary/data/datasource/internal-notes.datasource";
import { MaritalStatusDatasource } from "@beneficiary/data/datasource/marital-status.datasoruce";
import { PublicItineraryDatasource } from "@beneficiary/data/datasource/public-itinerary.datasource";
import { BeneficiariesSummaryMapper } from "@beneficiary/data/mappers/beneficiaries-summary.mapper";
import { BeneficiaryMapper } from "@beneficiary/data/mappers/beneficiary.mapper";
import { CenterTypeEnumMapper } from "@beneficiary/data/mappers/center-type-enum.mapper";
import { CenterTypeMapper } from "@beneficiary/data/mappers/center-type.mapper";
import { CountryEnumMapper } from "@beneficiary/data/mappers/country-enum.mapper";
import { CountryMapper } from "@beneficiary/data/mappers/country.mapper";
import { CreateBeneficiaryMapper } from "@beneficiary/data/mappers/create-beneficiary.mapper";
import { DependenceGradesEnumMapper } from "@beneficiary/data/mappers/dependence-grades-enum.mapper";
import { DependenceGradesMapper } from "@beneficiary/data/mappers/dependence-grades.mapper";
import { DisabilityTypesEnumMapper } from "@beneficiary/data/mappers/disability-type-enum.mapper";
import { DisabilityTypesMapper } from "@beneficiary/data/mappers/disability-type.mapper";
import { DistrictMapper } from "@beneficiary/data/mappers/district.mapper";
import { EditBeneficiaryMapper } from "@beneficiary/data/mappers/edit-beneficiary.mapper";
import { ExpenseRecurrenceEnumMapper } from "@beneficiary/data/mappers/expense-recurrence-enum.mapper";
import { ExpenseRecurrenceMapper } from "@beneficiary/data/mappers/expense-recurrence.mapper";
import { ExpenseTypeEnumMapper } from "@beneficiary/data/mappers/expense-type-enum.mapper";
import { ExpenseTypeMapper } from "@beneficiary/data/mappers/expense-type.mapper";
import { ExpenseMapper } from "@beneficiary/data/mappers/expense.mapper";
import { CreateExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/create-external-derivation.mapper";
import { EditExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/edit-external-derivation.mapper";
import { ExternalDerivationMapper } from "@beneficiary/data/mappers/external-derivation/external-derivation.mapper";
import { ExternalDerivationsMapper } from "@beneficiary/data/mappers/external-derivation/external-derivations.mapper";
import { IncomeMapper } from "@beneficiary/data/mappers/income.mapper";
import { CreateInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/create-internal-note.mapper";
import { EditInternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/edit-internal-note.mapper";
import { InternalNoteMapper } from "@beneficiary/data/mappers/internal-notes/internal-note.mapper";
import { InternalNotesMapper } from "@beneficiary/data/mappers/internal-notes/internal-notes.mapper";
import { MaritalStatusEnumMapper } from "@beneficiary/data/mappers/marital-status-enum.mapper";
import { MaritalStatusMapper } from "@beneficiary/data/mappers/marital-status.mapper";
import { CreatePublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/create-public-itinerary.mapper";
import { EditPublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/edit-public-itinerary.mapper";
import { PublicItinerariesMapper } from "@beneficiary/data/mappers/public-itinerary/public-itineraries.mapper";
import { PublicItineraryMapper } from "@beneficiary/data/mappers/public-itinerary/public-itinerary.mapper";
import { RelationshipEnumMapper } from "@beneficiary/data/mappers/relationship-enum.mapper";
import { RelationshipMapper } from "@beneficiary/data/mappers/relationship.mapper";
import { TherapyTypeEnumMapper } from "@beneficiary/data/mappers/therapy-type-enum.mapper";
import { TherapyTypesMapper } from "@beneficiary/data/mappers/therapy-type.mapper";
import { BeneficiaryRepository } from "@beneficiary/data/repositories/beneficiary.repository";
import { CenterTypesRepository } from "@beneficiary/data/repositories/center-types.repository";
import { CountryRepository } from "@beneficiary/data/repositories/country.repository";
import { DistrictsRepository } from "@beneficiary/data/repositories/districts.repository";
import { ExternalDerivationRepository } from "@beneficiary/data/repositories/external-derivation.repository";
import { InternalNotesRepository } from "@beneficiary/data/repositories/internal-notes.repository";
import { MaritalStatusRepository } from "@beneficiary/data/repositories/marital-status.repository";
import { PublicItineraryRepository } from "@beneficiary/data/repositories/public-itinerary.repository";
import { CreateBeneficiaryUseCase } from "@beneficiary/domain/usecases/create-beneficiary.usecase";
import { EditBeneficiaryUseCase } from "@beneficiary/domain/usecases/edit-beneficiary.usecase";
import { CreateExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/create-external-derivation.usecase";
import { DeleteExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/delete-external-derivation.usecase";
import { EditExternalDerivationUseCase } from "@beneficiary/domain/usecases/external-derivation/edit-external-derivation.usecase";
import { GetAllExternalDerivationsUseCase } from "@beneficiary/domain/usecases/external-derivation/get-all-external-derivations.usecase";
import { GetAssociateServiceToDependenceUseCase } from "@beneficiary/domain/usecases/get-all-associate-service-to-dependence.usecase";
import { GetAllBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiaries.usecase";
import { GetAllBeneficiaryIdentificationTypesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiary-identification-types.usecase";
import { GetAllCenterTypesUseCase } from "@beneficiary/domain/usecases/get-all-center-types.usecase";
import { GetCountriesUseCase } from "@beneficiary/domain/usecases/get-all-countries.usecase";
import { GetDependenceGradesUseCase } from "@beneficiary/domain/usecases/get-all-dependence-grades.usecase";
import { GetAllDistrictsUseCase } from "@beneficiary/domain/usecases/get-all-disctricts.usecase";
import { GetAllExpenseRecurrencesUseCase } from "@beneficiary/domain/usecases/get-all-expense-recurrences.usecase";
import { GetAllExpenseTypesUseCase } from "@beneficiary/domain/usecases/get-all-expense-types.usecase";
import { GetMaritalStatusesUseCase } from "@beneficiary/domain/usecases/get-all-marital-statuses.usecase";
import { GetAllRelationshipsUseCase } from "@beneficiary/domain/usecases/get-all-relationships.usecase";
import { GetTherapyTypesUseCase } from "@beneficiary/domain/usecases/get-all-therapy-types.usecase";
import { GetBeneficiaryByIdUseCase } from "@beneficiary/domain/usecases/get-beneficiary-by-id.usecase";
import { GetRecentModifiedBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-recent-modified-beneficiaries.usecase";
import { CreateInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/create-internal-note.usecase";
import { DeleteInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/delete-internal-note.usecase";
import { EditInternalNoteUseCase } from "@beneficiary/domain/usecases/internal-notes/edit-internal-note.usecase";
import { GetAllInternalNotesByUsecase } from "@beneficiary/domain/usecases/internal-notes/get-all-internal-notes-by.usecase";
import { CreatePublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/create-public-itinerary.usecase";
import { DeletePublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/delete-public-itinerary.usecase";
import { EditPublicItineraryUseCase } from "@beneficiary/domain/usecases/public-itinerary/edit-public-itinerary.usecase";
import { GetAllPublicItinerariesUsecase } from "@beneficiary/domain/usecases/public-itinerary/get-all-public-itineraries.usecase";
import { SearchBeneficiariesByUseCase } from "@beneficiary/domain/usecases/search-beneficiaries-by.usecase";
import { AddressTabViewModel } from "@beneficiary/presentation/components/beneficiary-form/address/address-tab.viewmodel";
import { DocumentsTabViewModel } from "@beneficiary/presentation/components/beneficiary-form/documents/document-tab.viewmodel";
import { ExpenseFormViewModel } from "@beneficiary/presentation/components/beneficiary-form/economyData/expenses/expense-form.viewmodel";
import { EntitiesTabViewModel } from "@beneficiary/presentation/components/beneficiary-form/entities/entities-tab.viewmodel";
import { ExternalDerivationViewModel } from "@beneficiary/presentation/components/beneficiary-form/external-derivation/external-derivation.viewmodel";
import { ExternalDerivationTabFormViewModel } from "@beneficiary/presentation/components/beneficiary-form/external-derivation/form/external-derivation-tab-form.viewmodel";
import { GeneralViewModel } from "@beneficiary/presentation/components/beneficiary-form/general/general.viewmodel";
import { InternalNoteTabViewModel } from "@beneficiary/presentation/components/beneficiary-form/internal-note/internal-note-tab.viewmodel";
import { PublicItineraryTabViewModel } from "@beneficiary/presentation/components/beneficiary-form/public-itinerary/public-itinerary-tab.viewmodel";
import { CreateBeneficiaryPageViewModel } from "@beneficiary/presentation/pages/create/create-beneficiary-page.viewmodel";
import { BeneficiaryDetailPageViewModel } from "@beneficiary/presentation/pages/detail/detail-beneficiary-page.viewmodel";
import { EditBeneficiaryPageViewModel } from "@beneficiary/presentation/pages/edit/edit-beneficiary-page.viewmodel";
import { BeneficiaryListPageViewModel } from "@beneficiary/presentation/pages/list/beneficiary-list-page-viewmodel";
import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { ContainerModule } from "inversify";
import { AddressDatasource } from "./data/datasource/address.datasource";
import { CenterTypeDatasource } from "./data/datasource/center-type.datasource";
import { ExpenseDatasource } from "./data/datasource/expense.datasource";
import { IncomeDatasource } from "./data/datasource/income.datasource";
import { RelativesDatasource } from "./data/datasource/relatives.datasource";
import { AddressMapper } from "./data/mappers/address/address.mapper";
import { AddressesMapper } from "./data/mappers/address/addresses.mapper";
import { AssociateServiceToDependenceEnumMapper } from "./data/mappers/associate-service-to-dependence-enum.mapper";
import { AssociateServiceToDependenceMapper } from "./data/mappers/associate-service-to-dependence.mapper";
import { BeneficiaryIdentificationTypeEnumMapper } from "./data/mappers/beneficiary-identification-type-enum.mapper";
import { BeneficiaryIdentificationTypeMapper } from "./data/mappers/beneficiary-identification-type.mapper";
import { BeneficiarySummaryMapper } from "./data/mappers/beneficiary-summary.mapper";
import { DiagnosisTypeEnumMapper } from "./data/mappers/diagnosis-type-enum.mapper";
import { DiagnosisTypeMapper } from "./data/mappers/diagnosis-type.mapper";
import { EmploymentCharacteristicsEnumMapper } from "./data/mappers/employment-characteristics-enum.mapper";
import { EmploymentCharacteristicsMapper } from "./data/mappers/employment-characteristics.mapper";
import { ExpensesMapper } from "./data/mappers/expenses.mapper";
import { IncomesMapper } from "./data/mappers/incomes.mapper";
import { CreateRelativeMapper } from "./data/mappers/relatives/create-relative.mapper";
import { EditRelativeMapper } from "./data/mappers/relatives/edit-relative.mapper";
import { RelativeMapper } from "./data/mappers/relatives/relative.mapper";
import { AddressRepository } from "./data/repositories/address.repository";
import { ExpenseRepository } from "./data/repositories/expense.repository";
import { IncomeRepository } from "./data/repositories/income.repository";
import { RelativesRepository } from "./data/repositories/relatives.repository";
import { CreateAddressUseCase } from "./domain/usecases/address/create-address.usecase";
import { DeleteAddressUseCase } from "./domain/usecases/address/delete-address.usecase";
import { EditAddressUseCase } from "./domain/usecases/address/edit-address.usecase";
import { EditBeneficiaryAddressUseCase } from "./domain/usecases/address/edit-beneficiary-address.usecase";
import { GetAddressByIdUseCase } from "./domain/usecases/address/get-address-by-id.usecase";
import { SaveAddressUseCase } from "./domain/usecases/address/save-address-data.usecase";
import { CreateExpenseUseCase } from "./domain/usecases/economic-data/expense/create-expense.usecase";
import { DeleteExpenseUseCase } from "./domain/usecases/economic-data/expense/delete-expense.usecase";
import { EditExpenseUseCase } from "./domain/usecases/economic-data/expense/edit-expense.usecase";
import { GetAllExpensesByUseCase } from "./domain/usecases/economic-data/expense/get-all-expense-by.usecase";
import { CreateIncomeUseCase } from "./domain/usecases/economic-data/income/create-income.usecase";
import { DeleteIncomeUseCase } from "./domain/usecases/economic-data/income/delete-income.usecase";
import { EditIncomeUseCase } from "./domain/usecases/economic-data/income/edit-income.usecase";
import { GetAllIncomesByUsecase } from "./domain/usecases/economic-data/income/get-all-income-by.usecase";
import { GetAllDiagnosisTypesUseCase } from "./domain/usecases/get-all-diagnosis-types.usecase";
import { GetDisabilityTypesUseCase } from "./domain/usecases/get-all-disability-types.usecase";
import { GetAllEmploymentCharacteristicsUseCase } from "./domain/usecases/get-all-employment-characteristics.usecase";
import { CreateRelativesUsecase } from "./domain/usecases/relatives/create-relatives.usecase";
import { DeleteRelativesUsecase } from "./domain/usecases/relatives/delete-relatives.usecase";
import { EditRelativesUsecase } from "./domain/usecases/relatives/edit-relatives.usecase";
import { GetAllRelativesUsecase } from "./domain/usecases/relatives/get-all-relatives.usecase";
import { AddressFormViewModel } from "./presentation/components/beneficiary-form/address/address-form.viewmodel";
import { BeneficiaryFormViewModel } from "./presentation/components/beneficiary-form/beneficiary-form.viewmodel";
import { EconomicDataTabViewModel } from "./presentation/components/beneficiary-form/economyData/beneficiary-economic-data-tab.viewmodel";
import { RelativeFormViewModel } from "./presentation/components/beneficiary-form/relatives/form/relative-form.viewmodel";
import { RelativesTabViewModel } from "./presentation/components/beneficiary-form/relatives/relatives-tab.viewmodel";

const beneficiaryModule = new DiModuleBuilder();

beneficiaryModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(AddressDatasource).toSelf();
        bind(BeneficiaryDatasource).toSelf();
        bind(CenterTypeDatasource).toSelf();
        bind(RelativesDatasource).toSelf();
        bind(CountriesDatasource).toSelf();
        bind(DistrictDatasource).toSelf();
        bind(ExpenseDatasource).toSelf();
        bind(ExternalDerivationDatasource).toSelf();
        bind(IncomeDatasource).toSelf();
        bind(InternalNotesDatasource).toSelf();
        bind(MaritalStatusDatasource).toSelf();
        bind(PublicItineraryDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(AddressesMapper).toSelf();
        bind(AddressMapper).toSelf();
        bind(AssociateServiceToDependenceEnumMapper).toSelf();
        bind(AssociateServiceToDependenceMapper).toSelf();
        bind(BeneficiariesSummaryMapper).toSelf();
        bind(BeneficiaryIdentificationTypeEnumMapper).toSelf();
        bind(BeneficiaryIdentificationTypeMapper).toSelf();
        bind(BeneficiaryMapper).toSelf();
        bind(BeneficiarySummaryMapper).toSelf();
        bind(CenterTypeEnumMapper).toSelf();
        bind(CenterTypeMapper).toSelf();
        bind(CountryEnumMapper).toSelf();
        bind(CountryMapper).toSelf();
        bind(CreateBeneficiaryMapper).toSelf();
        bind(CreateExternalDerivationMapper).toSelf();
        bind(CreateInternalNoteMapper).toSelf();
        bind(CreatePublicItineraryMapper).toSelf();
        bind(CreateRelativeMapper).toSelf();
        bind(DependenceGradesEnumMapper).toSelf();
        bind(DependenceGradesMapper).toSelf();
        bind(DiagnosisTypeEnumMapper).toSelf();
        bind(DiagnosisTypeMapper).toSelf();
        bind(DisabilityTypesEnumMapper).toSelf();
        bind(DisabilityTypesMapper).toSelf();
        bind(DistrictMapper).toSelf();
        bind(EditBeneficiaryMapper).toSelf();
        bind(EditExternalDerivationMapper).toSelf();
        bind(EditInternalNoteMapper).toSelf();
        bind(EditPublicItineraryMapper).toSelf();
        bind(EditRelativeMapper).toSelf();
        bind(EmploymentCharacteristicsEnumMapper).toSelf();
        bind(EmploymentCharacteristicsMapper).toSelf();
        bind(ExpenseMapper).toSelf();
        bind(ExpenseRecurrenceEnumMapper).toSelf();
        bind(ExpenseRecurrenceMapper).toSelf();
        bind(ExpensesMapper).toSelf();
        bind(ExpenseTypeEnumMapper).toSelf();
        bind(ExpenseTypeMapper).toSelf();
        bind(ExternalDerivationMapper).toSelf();
        bind(ExternalDerivationsMapper).toSelf();
        bind(IncomeMapper).toSelf();
        bind(IncomesMapper).toSelf();
        bind(InternalNoteMapper).toSelf();
        bind(InternalNotesMapper).toSelf();
        bind(MaritalStatusEnumMapper).toSelf();
        bind(MaritalStatusMapper).toSelf();
        bind(PublicItinerariesMapper).toSelf();
        bind(PublicItineraryMapper).toSelf();
        bind(RelationshipEnumMapper).toSelf();
        bind(RelationshipMapper).toSelf();
        bind(RelativeMapper).toSelf();
        bind(TherapyTypeEnumMapper).toSelf();
        bind(TherapyTypesMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(AddressRepository).toSelf();
        bind(BeneficiaryRepository).toSelf().inSingletonScope();
        bind(CenterTypesRepository).toSelf();
        bind(CountryRepository).toSelf();
        bind(DistrictsRepository).toSelf();
        bind(ExpenseRepository).toSelf();
        bind(ExternalDerivationRepository).toSelf();
        bind(IncomeRepository).toSelf();
        bind(InternalNotesRepository).toSelf();
        bind(MaritalStatusRepository).toSelf();
        bind(PublicItineraryRepository).toSelf();
        bind(RelativesRepository).toSelf();
    }),
    useCases: new ContainerModule((bind) => {
        bind(CreateAddressUseCase).toSelf();
        bind(CreateBeneficiaryUseCase).toSelf();
        bind(CreateExpenseUseCase).toSelf();
        bind(CreateExternalDerivationUseCase).toSelf();
        bind(CreateIncomeUseCase).toSelf();
        bind(CreateInternalNoteUseCase).toSelf();
        bind(CreatePublicItineraryUseCase).toSelf();
        bind(CreateRelativesUsecase).toSelf();
        bind(DeleteAddressUseCase).toSelf();
        bind(DeleteRelativesUsecase).toSelf();
        bind(DeleteExpenseUseCase).toSelf();
        bind(DeleteExternalDerivationUseCase).toSelf();
        bind(DeleteIncomeUseCase).toSelf();
        bind(DeleteInternalNoteUseCase).toSelf();
        bind(DeletePublicItineraryUseCase).toSelf();
        bind(EditAddressUseCase).toSelf();
        bind(EditBeneficiaryAddressUseCase).toSelf();
        bind(EditBeneficiaryUseCase).toSelf();
        bind(EditExpenseUseCase).toSelf();
        bind(EditExternalDerivationUseCase).toSelf();
        bind(EditIncomeUseCase).toSelf();
        bind(EditInternalNoteUseCase).toSelf();
        bind(EditPublicItineraryUseCase).toSelf();
        bind(EditRelativesUsecase).toSelf();
        bind(GetAddressByIdUseCase).toSelf();
        bind(GetAllBeneficiariesUseCase).toSelf();
        bind(GetAllBeneficiaryIdentificationTypesUseCase).toSelf();
        bind(GetAllCenterTypesUseCase).toSelf();
        bind(GetAllDistrictsUseCase).toSelf();
        bind(GetAllEmploymentCharacteristicsUseCase).toSelf();
        bind(GetAllExpenseRecurrencesUseCase).toSelf();
        bind(GetAllExpensesByUseCase).toSelf();
        bind(GetAllExpenseTypesUseCase).toSelf();
        bind(GetAllExternalDerivationsUseCase).toSelf();
        bind(GetAllIncomesByUsecase).toSelf();
        bind(GetAllInternalNotesByUsecase).toSelf();
        bind(GetAllPublicItinerariesUsecase).toSelf();
        bind(GetAllRelationshipsUseCase).toSelf();
        bind(GetAllRelativesUsecase).toSelf();
        bind(GetAssociateServiceToDependenceUseCase).toSelf();
        bind(GetBeneficiaryByIdUseCase).toSelf();
        bind(GetCountriesUseCase).toSelf();
        bind(GetDependenceGradesUseCase).toSelf();
        bind(GetAllDiagnosisTypesUseCase).toSelf();
        bind(GetDisabilityTypesUseCase).toSelf();
        bind(GetMaritalStatusesUseCase).toSelf();
        bind(GetRecentModifiedBeneficiariesUseCase).toSelf();
        bind(GetTherapyTypesUseCase).toSelf();
        bind(SaveAddressUseCase).toSelf();
        bind(SearchBeneficiariesByUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(AddressFormViewModel).toSelf();
        bind(AddressTabViewModel).toSelf();
        bind(BeneficiaryDetailPageViewModel).toSelf();
        bind(BeneficiaryFormViewModel).toSelf();
        bind(BeneficiaryListPageViewModel).toSelf();
        bind(CreateBeneficiaryPageViewModel).toSelf();
        bind(DocumentsTabViewModel).toSelf();
        bind(EconomicDataTabViewModel).toSelf();
        bind(EditBeneficiaryPageViewModel).toSelf();
        bind(EntitiesTabViewModel).toSelf();
        bind(ExpenseFormViewModel).toSelf();
        bind(ExternalDerivationTabFormViewModel).toSelf();
        bind(ExternalDerivationViewModel).toSelf();
        bind(GeneralViewModel).toSelf();
        bind(InternalNoteTabViewModel).toSelf();
        bind(PublicItineraryTabViewModel).toSelf();
        bind(RelativeFormViewModel).toSelf();
        bind(RelativesTabViewModel).toSelf();
    }),
}));

beneficiaryModule.registerModule();

export { beneficiaryModule };
