import { Deposit } from "@entity/domain/models/deposit.model";
import { DepositFormValuesValidated } from "@entity/presentation/component/entity-form/deposits/form/deposit.form";
import { injectable } from "inversify";

@injectable()
export class EditDepositMapper {
    mapToDeposit(deposit: DepositFormValuesValidated): Deposit {
        return new Deposit(
            deposit.id as number,
            deposit.concept,
            deposit.type,
            deposit.amount,
            deposit.name,
            deposit.depositDate?.toString() ?? "",
            deposit.identificationType,
            deposit.identification,
            deposit.street,
            deposit.zipCode,
            deposit.town,
            deposit.province,
            deposit.grants,
            deposit.callYear,
            deposit.executionYear,
            deposit.callStatus,
            deposit.entity as number,
            deposit.relatedProject ?? undefined,
            deposit.relatedDocument ?? undefined,
            deposit.acquisitionDate?.toString() ?? "",
            deposit.othersType,
            deposit.observations,
            undefined,
        );
    }
}
