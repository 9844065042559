import { IsNotEmpty, IsString } from "class-validator";

export enum IVATypeEnumDto {
    "Cero" = "0",
    "Five" = "5",
    "Ten" = "10",
    "TwentyOne" = "21",
}

export class IVATypeDto {
    // @IsEnum(IVATypeEnumDto) TODO: Se deshabilitó porque backend manda number en response del gasto, en lugar de string
    @IsNotEmpty()
    key!: IVATypeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
